import { debounce } from 'lodash-es';
import { Updates } from 'timestamp-collection';
import debugLog from '../models/debug';
import { getUser } from '../models/user';
import { collections } from '../models/user-data';
import { APIError, requestApi } from './api';
import { reportError } from './error-handling';
import { onTabHidden } from './localstorage';

const log = (...args: any[]) => {
  debugLog({ logName: 'user', logColor: 'violet' }, ...args);
};

//
// Entry groups (user data stored in Supabase)
//

let isSyncing = false;
const fiveMinutes = 5 * 60 * 1000;

// Lazily sync collections
export const syncUserDataLazily = debounce(syncUserDataWithAPI, fiveMinutes);

// Sync whenever user changes tabs (if a sync is pending)
onTabHidden(() => syncUserDataLazily.flush());

/**
 * Sync collection updates to API
 * @param {Collection[]} collections
 * @returns {Promise<boolean>}
 */
export async function syncUserDataWithAPI() {
  if (isSyncing) return;

  const user = getUser();
  if (!user) return;

  interface RequestData {
    userId: string;
    state: Record<string, { hash: string; updates: Updates<any> }>;
  }

  interface ResponseData {
    data: Record<string, { hash: string; export?: string }>;
    nonFatalErrors?: Record<string, any>;
  }

  const dataToSync: RequestData = {
    userId: user.id,
    state: Object.fromEntries(
      collections.map(({ name, collection }) => [
        name,
        { hash: collection.hash, updates: collection.getUpdates() },
      ])
    ),
  };

  isSyncing = true;
  log('Syncing collections to API');
  let results: ResponseData['data'];
  try {
    const response = (
      await requestApi(
        'https://didgjhjnevdixtjltmho.functions.supabase.co/sync-user-data',
        dataToSync,
        { Authorization: `Bearer ${user.accessToken}` }
      )
    ).json as ResponseData;
    if (!response.data) throw new APIError('Invalid response');
    if (response.nonFatalErrors) reportError('Sync failures', response.nonFatalErrors);
    results = response.data;
    isSyncing = false;
  } catch (error) {
    // Ensure isSyncing is always set to false
    isSyncing = false;
    reportError(error, 'Failed to sync collections');
    return;
  }

  // If user data has changed since sync started, don't do anything with result
  const currentUser = getUser();
  if (!currentUser || currentUser.id !== user.id) {
    log("Ignoring results of syncing prior user's data");
    return;
  }

  Object.entries(results).forEach(([name, { hash, export: exportFromAPI }]) => {
    const { collection } = collections.find((obj) => obj.name === name)!;
    const { hash: priorHash, updates: priorUpdates } = dataToSync.state[name];

    // Fully in sync, clear all updates
    if (collection.hash === hash) return collection.clearUpdates();

    // Clear updates already synced to API (leave those that were added while we were waiting)
    collection.clearUpdates(priorUpdates);

    // Mostly in sync (no new data from API, only new data from this client)
    if (priorHash === hash) return;

    // Out of sync. Replace data in collection with data from API
    if (exportFromAPI) {
      // Save updates to replay after sync
      const updatesToReplay = collection.getUpdates();
      // Import data from API
      try {
        collection.import(exportFromAPI);
        // Hash should now match
        if (collection.hash !== hash) {
          reportError(`Hash doesn't match after updates`, name, collection.hash, hash);
        }
      } catch (error) {
        reportError(error, 'Failed to import from API', { name, exportFromAPI });
      }
      // No need to track updates from API
      collection.clearUpdates();
      // Finally, re-play new updates (since sync occurred)
      collection.import(updatesToReplay, false);
    }
  });
}
