import { computed } from '@preact/signals';
import { forwardRef } from 'preact/compat';
import { useRef, useState } from 'preact/hooks';
import { getCurrentEpisode, requestedState, state } from '../../models/audio-signals';
import { pause, play } from '../../models/audio-state';
import style from './style.css';

const isPlayingSignal = computed(
  () =>
    state.value === 'playing' ||
    (requestedState.value === 'playing' && !['error', 'unloaded'].includes(state.value))
);

const PlayerBar = forwardRef(
  ({ isLogoVisible = true, expand, thumbUrl }, logoBoxRef) => {
    const episode = getCurrentEpisode();
    const text = episode ? episode.title : 'Select an episode to play...';

    const containerRef = useRef();
    const [dragState, setDragState] = useState();
    const startDrag = (event) => {
      setDragState({ startY: event.pageY });
    };
    const handleDrag = (event) => {
      if (dragState && dragState.startY - event.pageY > 20) {
        expand();
        setDragState();
      }
    };
    const endDrag = () => setDragState();
    const logoBoxStyles = [
      isLogoVisible ? '' : 'visibility: hidden',
      thumbUrl ? `background-image: url(${thumbUrl})` : 'width: 0; border: 0;',
    ]
      .filter(Boolean)
      .join(';');

    return (
      <div
        class={style['player-bar']}
        onClick={(event) => {
          if (event.target.nodeName !== 'BUTTON') {
            // Ignore clicks on buttons in nav bar
            expand();
          }
        }}
        onPointerDown={startDrag}
        onPointerMove={handleDrag}
        onPointerCancel={endDrag}
        onPointerUp={endDrag}
        ref={containerRef}
      >
        <div
          ref={logoBoxRef}
          class={style['player-bar__podcast-logo']}
          style={logoBoxStyles}
        ></div>
        <div class={style['player-bar__text']}>{text}</div>
        {isPlayingSignal.value ? (
          <button
            class={style['player-bar__button']}
            onClick={() => pause()}
            disabled={!thumbUrl}
          >
            <svg class={[style.pauseIcon]} viewBox="0 0 128 128">
              <path d="M16 16h40v96h-40zM72 16h40v96h-40z"></path>
            </svg>
          </button>
        ) : (
          <button
            class={style['player-bar__button']}
            onClick={() => play()}
            disabled={!thumbUrl}
          >
            <svg class={[style.playIcon]} viewBox="0 0 128 128">
              <path d="M24 16l80 48-80 48z"></path>
            </svg>
          </button>
        )}
      </div>
    );
  }
);

export default PlayerBar;
