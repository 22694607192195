import toast from 'react-hot-toast';
import log from '../models/debug';
import { Episode } from '../models/podcasts';
import { BumpPriorityAction, fetchEpisode } from './api';

export function reportError(error: any, ...details: any) {
  log('Reporting error', error, ...details);
  if (details.length) {
    console.warn('Reporting error:', '\n', error, '\n', ...details);
  } else {
    console.warn('Reporting error:', '\n', error);
  }
  if (window.Bugsnag) {
    window.Bugsnag.notify(error);
  } else {
    setTimeout(() => {
      if (error instanceof Error) throw error;
      throw new Error(error);
    }, 0);
  }
}

export function reportWarning(warning: string, ...details: any) {
  log('Reporting warning', warning, ...details);
  if (details.length) {
    console.warn('Reporting warning:', '\n', warning, '\n', ...details);
  } else {
    console.warn('Reporting warning:', '\n', warning);
  }
  setTimeout(() => {
    throw new Error(warning);
  }, 0);
}

//
// Handle audio not loading & playing
//

export async function checkEpisodeAfterFailure(
  episode: Episode,
  action: BumpPriorityAction
) {
  const {
    status,
    episode: { isSaved },
  } = await fetchEpisode(episode.podcastId, episode.guidHash, action);
  let retryInSeconds;
  if (isSaved) retryInSeconds = 0;
  else if (status === 'saving_audio_file') retryInSeconds = 5;
  else if (status === 'pending') retryInSeconds = 10;
  return { status, isSaved, retryInSeconds };
}

export async function showMessageWhenPlaybackFails(
  isSaved: boolean,
  status: string | undefined,
  action: 'play' | 'download'
) {
  if (isSaved) {
    toast.error(`Failed to ${action} audio. Please try again`);
  } else if (status === 'saving_audio_file') {
    toast.error(
      `Sorry, we're almost done preparing this audio file. Please try again in a few seconds.`
    );
  } else if (status === 'pending') {
    toast.error(
      `Sorry, this audio file is not yet ready to ${action}. We've bumped it's priority so it should be ready in a minute or two.`
    );
  } else {
    log('Audio playback error unhandled', { isSaved, status });
  }
}
