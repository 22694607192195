import { h } from 'preact';
import { Link } from 'preact-router';
import { useRef, useState } from 'preact/hooks';
import toast from 'react-hot-toast';
import AppleIcon from '../../assets/icons/apple-logo.svg';
import GoogleIcon from '../../assets/icons/google-g.svg';
import LoadingIcon from '../../assets/icons/loading-circle.svg';
import UserIcon from '../../assets/icons/user.svg';
import { useUserActions } from '../../models/user';
import { Dialog } from '../dialog';

export function SignInOrSignUpModal({ onClose }: { onClose: () => void }) {
  const inputClassName =
    'block w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-indigo-500 focus:ring-2 focus:outline-none text-sm';
  const tabClassName =
    'inline-block py-2 px-3 border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300';
  const activeTabClassName =
    'inline-block py-2 px-3 text-blue-600 border-b-2 border-blue-600';

  const containerRef = useRef<HTMLDivElement>(null);

  const [initialEmail, setInitialEmail] = useState('');
  const [activeTab, setActiveTab] = useState('sign-in');

  const setActiveTabAndCopyEmail = (activeTabName: string) => {
    const emailInput = document.querySelector('input[type="email"]');
    if (emailInput) setInitialEmail((emailInput as HTMLInputElement).value);
    setActiveTab(activeTabName);
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const [isResettingPassword, setResettingPassword] = useState(false);

  const { signInWithOAuth, signInWithPassword, resetPassword, signUpWithPassword } =
    useUserActions();

  const onSignInSubmit = (event: Event) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const data = new FormData(form);
    const email = (data.get('email') as string).trim();
    const password = data.get('password') as string;

    setSubmitting(true);
    signInWithPassword({ email, password }).finally(() => {
      setSubmitting(false);
    });
  };

  const onResetPasswordClick = (event: Event) => {
    event.preventDefault();
    const emailInput = containerRef.current!.querySelector(
      'input[name="email"]'
    ) as HTMLInputElement;

    const email = emailInput.value.trim();
    if (!email || !emailInput.checkValidity()) {
      emailInput.focus();
      toast.error('Please enter a valid email address');
      return;
    }

    setResettingPassword(true);
    resetPassword(emailInput.value.trim()).finally(() => {
      setResettingPassword(false);
    });
  };

  const onCreateAccountSubmit = (event: Event) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const data = new FormData(form);
    const email = (data.get('email') as string).trim();
    const password = data.get('password') as string;
    const passwordConfirmed = data.get('password_confirmed') as string;

    if (password !== passwordConfirmed) {
      toast.error(`Passwords don't match`);
      return;
    }

    setSubmitting(true);
    signUpWithPassword({ email, password }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <Dialog onClose={() => {}}>
      <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4" ref={containerRef}>
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <UserIcon
              class="h-6 w-6 text-green-800"
              stroke="currentColor"
              // fill=""
            />
          </div>
          <div class="mt-3 grow text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              class="text-base font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Sign In or Create Account to Continue
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                You're just one step away from using the app!
              </p>
            </div>
            <div class="flex w-full flex-col gap-4 text-left">
              <div className="mt-4 flex flex-wrap gap-2">
                <button
                  class="button button--google"
                  onClick={() => signInWithOAuth({ provider: 'google' })}
                >
                  <GoogleIcon />
                  <span>Continue with Google</span>
                </button>

                <button
                  class="button button--apple"
                  onClick={() => signInWithOAuth({ provider: 'apple' })}
                >
                  <AppleIcon style="margin-top: -2px" />
                  <span>Continue with Apple</span>
                </button>
              </div>
              <p class="text-center"> - or -</p>

              {/* Tabs */}
              <div class="rounded-md border p-4 pt-2">
                <ul class="mb-2 flex flex-wrap gap-2 text-center text-sm">
                  <li>
                    <button
                      class={
                        activeTab === 'sign-in' ? activeTabClassName : tabClassName
                      }
                      onClick={() => setActiveTabAndCopyEmail('sign-in')}
                    >
                      Sign In
                    </button>
                  </li>
                  <li>
                    <button
                      class={
                        activeTab === 'create-account'
                          ? activeTabClassName
                          : tabClassName
                      }
                      onClick={() => setActiveTabAndCopyEmail('create-account')}
                    >
                      Create Account
                    </button>
                  </li>
                </ul>
                {activeTab === 'sign-in' && (
                  <form class="flex flex-col gap-4" onSubmit={onSignInSubmit}>
                    <label>
                      <strong class="mb-1 block">Email Address</strong>
                      <input
                        class={inputClassName}
                        name="email"
                        type="email"
                        required
                        defaultValue={initialEmail}
                      />
                    </label>
                    <label>
                      <strong class="mb-1 block">Password</strong>
                      <input
                        class={inputClassName}
                        name="password"
                        type="password"
                        minLength={12}
                        required
                      />
                    </label>
                    <button
                      class="button button--submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Sign In
                      {isSubmitting && <LoadingIcon />}
                    </button>
                    <p>
                      Forgot your password?{' '}
                      <a
                        href="#"
                        onClick={onResetPasswordClick}
                        class="text-blue-500 underline"
                      >
                        {!isResettingPassword
                          ? 'Send password reset email'
                          : 'Sending email...'}
                      </a>
                      .
                    </p>
                  </form>
                )}
                {activeTab === 'create-account' && (
                  <form class="flex flex-col gap-4" onSubmit={onCreateAccountSubmit}>
                    <label>
                      <strong class="mb-1 block">Email Address</strong>
                      <input
                        class={inputClassName}
                        name="email"
                        type="email"
                        required
                        defaultValue={initialEmail}
                      />
                    </label>
                    <label>
                      <strong class="mb-1 block">Password</strong>
                      <input
                        class={inputClassName}
                        name="password"
                        type="password"
                        minLength={12}
                        required
                      />
                    </label>
                    <label>
                      <strong class="mb-1 block">Confirm Password</strong>
                      <input
                        class={inputClassName}
                        name="password_confirmed"
                        type="password"
                        minLength={12}
                        required
                      />
                    </label>

                    <button
                      class="button button--submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create Account
                      {isSubmitting && <LoadingIcon />}
                    </button>
                  </form>
                )}
              </div>
            </div>
            <p class="mt-3">
              <small>
                By signing in and using this app you acknowledge and agree to abide by
                our{' '}
                <Link
                  href="/terms-of-use"
                  target="_blank"
                  class="text-blue-500 underline"
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  class="text-blue-500 underline"
                >
                  Privacy Policy
                </Link>
                .
              </small>
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
