import { debounce } from 'lodash-es';
import { useMachine } from 'preact-robot';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { createMachine, state, transition } from 'robot3';
import waitForTimers from 'wait-for-timers';
import { getCurrentEpisode } from '../../models/audio-signals';
import { usePodcastsStore } from '../../models/podcasts';
import getThumbUrl from '../../utils/get-thumb';
import { isIOS } from '../../utils/helpers';
import AudioPlayer from '../audio-player';
import BottomNavBar from '../bottom-nav-bar';
import PlayerBar from '../player-bar';
import DraggablePanel from './draggable-panel';
import style from './style.scss';

// Simple state machine for expanding / collapsing
const playerPanelStateMachine = createMachine({
  collapsed: state(transition('expand', 'expanding')),
  expanding: state(transition('done', 'expanded')),
  expanded: state(transition('collapse', 'collapsing')),
  collapsing: state(transition('done', 'collapsed')),
});

/**
 * An expandable and collapsable podcast player panel
 * Heavily inspired built-in Podcasts app on iOS
 * When expanding/collapsing, we use a FLIP animation to transition between the small podcast
 * logo on the PlayerBar (at the bottom) to the large podcast logo on the expanded panel
 * This creates a sense of fluidity as the panel expands and collapses
 */
const PlayerPanel = ({ isHidden = false }) => {
  const episode = getCurrentEpisode();
  // const panelCommand = usePlayerStore((s) => s.panelCommand);
  const podcast = episode
    ? usePodcastsStore.getState().podcasts[episode.podcastId]
    : undefined;
  const [stateMachine, send] = useMachine(playerPanelStateMachine);
  const [animationStyles, setAnimationStyles] = useState({});
  const [isNavHidden, setNavHidden] = useState(false);
  const { name: state } = stateMachine;
  const thumbUrl = podcast ? getThumbUrl(podcast, 200) : '';
  const scrollContainerRef = useRef();
  const largeLogoBoxRef = useRef();
  const playerBarLogoBoxRef = useRef();

  // Animate expanding/collapsing animation
  useLayoutEffect(() => {
    if (state === 'collapsed' && scrollContainerRef.current) {
      // Clean up side effects after collapsing
      const element = scrollContainerRef.current.firstChild;
      if (isIOS) {
        element.style.transition = '';
        element.style.transform = '';
        scrollContainerRef.current.style.overflowY = '';
      } else {
        element.style.transition = '';
        element.style.transform = '';
      }
    }

    // Show/hide bottom nav bar
    const navBarDurationMs = 300;

    // Duration of panel & logo box animations
    const durationMs = state === 'expanding' ? 500 : 400;

    if (state !== 'expanding' && state !== 'collapsing') return;

    // FLIP animation of logo box when expanding & collapsing
    const easing = 'ease';
    const tinyRect = playerBarLogoBoxRef.current.getBoundingClientRect();
    const bigRect = largeLogoBoxRef.current.getBoundingClientRect();

    // 1. First position
    const firstRect = state === 'expanding' ? tinyRect : bigRect;

    // 2. Last position
    const lastRect = state === 'expanding' ? bigRect : tinyRect;

    if (state === 'expanding') {
      setNavHidden(true);

      // 3. Invert - move to initial position
      const hiddenPanelOffset =
        scrollContainerRef.current.getBoundingClientRect().height; // translateY(100%);

      const boxStyles = {
        // Set top & left to match final state (same as large logo box)
        top: `${lastRect.top - hiddenPanelOffset}px`,
        left: `${lastRect.left}px`,
        display: 'block',
        // Transform to initial state (same as small player bar logo box)
        transform: [
          `translateY(${firstRect.top - lastRect.top + hiddenPanelOffset}px)`,
          `translateX(${firstRect.left - lastRect.left}px)`,
          `scale(${firstRect.width / lastRect.width})`,
        ].join(' '),
      };

      setAnimationStyles({
        box: Object.entries(boxStyles)
          .map(([k, v]) => `${k}: ${v}`)
          .join(';'),
        panel: `transition: transform ${durationMs}ms ${easing}`,
      });

      // 4. Play - remove transform to play animation
      requestAnimationFrame(() => {
        // Add transition and remove transform
        delete boxStyles.transform;
        boxStyles.transition = `transform ${durationMs * 0.95}ms ${easing} ${
          durationMs * 0.05
        }ms`;

        setAnimationStyles({
          box: Object.entries(boxStyles)
            .map(([k, v]) => `${k}: ${v}`)
            .join(';'),
          panel: `transition: transform ${durationMs}ms ${easing}`,
        });

        setTimeout(() => {
          send('done');
          setAnimationStyles({});
          avoidPullDownToRefreshOnIOS(scrollContainerRef.current);
        }, durationMs);
      });
    }

    if (state === 'collapsing') {
      // 3. Invert - move to initial position
      // const hiddenPanelOffset = window.innerHeight * 0.98; // translateY(100%);
      const boxStyles = {
        // Set top & left to match final state (same as small player bar logo box)
        top: `${lastRect.top}px`,
        left: `${lastRect.left}px`,
        display: 'block',
        // Transform to initial state (same as large logo box)
        transform: [
          `translateY(${firstRect.top - lastRect.top}px)`,
          `translateX(${firstRect.left - lastRect.left}px)`,
        ].join(' '),
      };

      setAnimationStyles({
        box: Object.entries(boxStyles)
          .map(([k, v]) => `${k}: ${v}`)
          .join(';'),
        // panel: `transform: translateY(100%); transition: transform ${durationMs}ms ${easing}`,
      });

      // 4. Play - remove transform to play animation
      waitForTimers(['raf', 'raf'], () => {
        // Add transition and change transform to a scale
        boxStyles.transform = `scale(${lastRect.width / firstRect.width})`;
        boxStyles.transition = `transform ${durationMs}ms ${easing}`;

        setAnimationStyles({
          box: Object.entries(boxStyles)
            .map(([k, v]) => `${k}: ${v}`)
            .join(';'),
          panel: `transform: translateY(100%); transition: transform ${
            durationMs * 1.4
          }ms ${easing}`,
        });

        // Slide up nav bar at end of animation
        setTimeout(
          () => {
            setNavHidden(false);
          },
          durationMs - navBarDurationMs - 150
        );

        setTimeout(() => {
          send('done');
          setAnimationStyles({});
        }, durationMs);
      });
    }
  }, [state]);

  // useEffect(() => {
  //   if (!panelCommand) return;

  //   // panelCommand has an integer at the end to force a state update
  //   // e.g. play-1 or pause-53
  //   const command = panelCommand.split('-')[0];

  //   if (state === 'collapsed' && command === 'expand') {
  //     send('expand');
  //   } else if (state === 'expanded' && command === 'collapse') {
  //     send('collapse');
  //   }
  // }, [panelCommand]);

  const panelStyles =
    animationStyles.panel ||
    (state === 'collapsed' || state === 'expanding'
      ? 'transform: translateY(100%)'
      : '');

  const veilStyles = state === 'expanding' || state === 'expanded' ? 'opacity: 1' : '';

  // On landing pages
  if (isHidden) {
    return <></>;
  }

  const episodeDescription = episode
    ? episode.description || episode.excerpt || ''
    : '';

  return (
    <>
      <PlayerBar
        ref={playerBarLogoBoxRef}
        isLogoVisible={state !== 'expanding' && state !== 'collapsing'}
        expand={() => (episode ? send('expand') : {})}
        thumbUrl={thumbUrl}
      />
      <DraggablePanel
        panelStyles={panelStyles}
        veilStyles={veilStyles}
        isExpanded={state === 'expanded'}
        ref={scrollContainerRef}
        collapse={() => send('collapse')}
        avoidPullDownToRefreshOnIOS={avoidPullDownToRefreshOnIOS}
      >
        <div
          class={classes(['__logo-box', `__logo-box--${state}`])}
          style={thumbUrl ? { backgroundImage: `url(${thumbUrl})` } : {}}
          ref={largeLogoBoxRef}
        ></div>
        {podcast ? (
          <>
            <h2 style={{ textAlign: 'center' }}>{episode.title}</h2>
            <p>{podcast.name}</p>
            <AudioPlayer />
            {episodeDescription.includes('<') ? (
              <div
                class={`${classes([
                  '__description',
                ])} [&_a]:text-blue-500 [&_a]:underline`}
                dangerouslySetInnerHTML={{
                  __html: episodeDescription,
                }}
              />
            ) : (
              <div
                class={`${classes([
                  '__description',
                ])} [&_a]:text-blue-500 [&_a]:underline`}
              >
                <p>{episodeDescription}</p>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </DraggablePanel>
      <div
        style={[animationStyles.box, thumbUrl && `background-image: url(${thumbUrl})`]
          .filter(Boolean)
          .join(';')}
        class={classes('__logo-box-animated')}
      ></div>
      <BottomNavBar isHidden={isNavHidden}></BottomNavBar>
    </>
  );
};

function classes(ems) {
  if (!Array.isArray(ems)) ems = [ems];
  return ems
    .filter((em) => typeof em === 'string')
    .map((em) => style['player-panel' + em])
    .join(' ');
}

// Set scrollTop to 1px on iOS to prevent "pull down to refresh" behavior
const avoidPullDownToRefreshOnIOS = debounce(
  (scrollContainer) => {
    if (!isIOS) return;

    if (scrollContainer.scrollTop !== 0) return;

    // Set scrollTop to 1 so that pulling down pulls on panel, and does not
    // trigger a pull-to-refresh on the page
    scrollContainer.scrollTop = 1;
    if (
      scrollContainer.scrollTop === 0 &&
      scrollContainer.scrollHeight > scrollContainer.clientHeight
    ) {
      console.warn('Failed to set scrollTop');
    }
  },
  80,
  { leading: true, trailing: true }
);

export default PlayerPanel;
