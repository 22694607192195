import { effect, signal } from '@preact/signals';
import { exposeOnWindow, secondsToTime } from '../utils/helpers';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import debugLog from './debug';
import { Episode } from './podcasts';

const log = (...args: any[]) => {
  debugLog({ logName: 'signals', logColor: 'DarkSeaGreen' }, ...args);
};

export type AudioState =
  | 'unloaded'
  | 'loading'
  | 'error'
  | 'paused'
  | 'seeking'
  | 'playing';
export type RequestedAudioState = '' | 'unloaded' | 'paused' | 'playing';

export const state = signal<AudioState>('unloaded');
export const requestedState = signal<RequestedAudioState>(''); // signal?
export const currentEpisode = signal<Episode | undefined>(undefined);
export const currentDuration = signal<number>(NaN);
export const currentSeek = signal<number>(0);
export const requestedSeek = signal<number>(NaN);
export const currentError = signal<string>('');
export const isFixingAudio = signal<boolean>(false);
export const playbackRate = signal<number>(
  getInitialValue(
    'SignalStore__playbackRate',
    (rate) => typeof rate === 'number' && rate >= 0.5 && rate <= 3,
    1.0
  )
);

effect(() => {
  setLazyValue('SignalStore__playbackRate', playbackRate.value);
});

let lastStateString = '';
effect(() => {
  // Reset requestedState if it equals the current state
  if (state.value === requestedState.value) {
    requestedState.value = '';
  }

  // Log state change
  const stateString = `State: ${state.value} ${
    requestedState.value ? `-> ${requestedState.value}` : ''
  } (seek = ${secondsToTime(currentSeek.peek(), 2)})`;
  if (stateString !== lastStateString) {
    log(stateString);
    lastStateString = stateString;
  }

  // Reset error when leaving error state
  if (state.value !== 'error') currentError.value = '';

  // Reset requestedSeek when leaving seeking state
  if (state.value !== 'seeking') requestedSeek.value = NaN;
});

export function getState() {
  return state.value;
}
export function getRequestedState() {
  return requestedState.value;
}
export function getCurrentSeek() {
  return currentSeek.value;
}
export function getRequestedSeek() {
  return requestedSeek.value;
}
export function getCurrentDuration() {
  return currentDuration.value;
}
export function getCurrentEpisode() {
  return currentEpisode.value;
}

// Expose previous state
const previousStates: (AudioState | undefined)[] = [];
effect(() => {
  previousStates.push(state.value);
  if (previousStates.length > 2) previousStates.shift();
});
export function getPreviousState() {
  return previousStates[0];
}

// Expose to window for debugging
exposeOnWindow({
  debugSignals: {
    state,
    requestedState,
    currentEpisode,
    currentDuration,
    currentSeek,
    requestedSeek,
    currentError,
  },
});
