import toast from 'react-hot-toast';
import create from 'zustand';
import {
  checkEpisodeAfterFailure,
  reportError,
  showMessageWhenPlaybackFails,
} from '../utils/error-handling';
import { urlToEpisodeCompoundId } from '../utils/helpers';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import {
  checkDownloadedFiles,
  deleteAudioFile,
  downloadAudioFile,
} from './audio-state';
import debugLog from './debug';
import { Episode, usePodcastsStore } from './podcasts';
import { supabase } from './user';
import {
  addToDownloads,
  getDownloadRequestedTimestamp,
  removeFromDownloads,
  useUserDataStore,
} from './user-data';

const log = (...args: any[]) => {
  debugLog({ logName: 'downloads', logColor: 'plum' }, ...args);
};

let lastSupabaseEventTime = Date.now();
supabase.auth.onAuthStateChange(() => {
  lastSupabaseEventTime = Date.now();
});

export type DownloadsStore = {
  // Used for tracking progress of downloading episodes
  downloadsProgress: Record<string, [number, number]>; // {'248021|a1dbe300': [0.2, <timestamp>]} 20% downloaded
  setProgress: (compoundId: string, percent: number) => void;
  // Used for downloading new episodes or removing downloads
  downloadEpisode: (episode: Episode, force?: boolean) => void;
  removeDownload: (compoundId: string) => void;
};

export const useDownloadsStore = create<DownloadsStore>()((set, get) => ({
  downloadsProgress: (function () {
    let progress: Record<string, [number, number]> = getInitialValue(
      'DownloadsStore__progress',
      (value) =>
        Boolean(
          value &&
            typeof value === 'object' &&
            Object.values(value).every(
              ([progress, timestamp]) => progress >= 0 && progress <= 1 && timestamp > 0
            )
        ),
      {}
    );
    // Ignore in-progress items, since they may be stale. If they are actively
    // downloading then service worker will let us know
    progress = Object.fromEntries(
      Object.entries(progress).filter(([k, v]) => v[0] === 1)
    );

    // Check downloaded podcasts once a minute unless there's a recent user data
    // related action
    checkDownloadedFilesAtInterval(1000 * 60);

    return progress;
  })(),
  setProgress: (compoundId, percent) => {
    if (percent >= 0 && percent <= 1) {
      set({
        downloadsProgress: {
          ...get().downloadsProgress,
          [compoundId]: [percent, Date.now()],
        },
      });
    } else {
      throw new Error(`Invalid progress percent ${percent}`);
    }
  },
  downloadEpisode: (episode: Episode, requestFile) => {
    const compoundId = `${episode.podcastId}|${episode.guidHash}`;
    // Add episode id to list of downloads
    addToDownloads(compoundId);
    const { downloadsProgress } = get();
    if (
      requestFile === true ||
      (requestFile !== false && typeof downloadsProgress[compoundId] === 'undefined')
    ) {
      downloadAudioFile(episode);
    }
  },
  removeDownload: (id) => {
    removeFromDownloads(id);

    let episode = usePodcastsStore.getState().idToEpisode(id);
    if (episode) {
      deleteAudioFile(episode)
        .then(() => deleteProgress(id))
        .catch((error) => {
          log('Failed to remove downloaded audio', id, error);
        });
    } else {
      reportError('Unable to remove downloaded audio for missing episode', id);
    }
  },
}));

function deleteProgress(id: string) {
  const clonedValue = Object.assign({}, useDownloadsStore.getState().downloadsProgress);
  delete clonedValue[id];
  useDownloadsStore.setState({
    downloadsProgress: clonedValue,
  });
}

// Save updates in LocalStorage
useDownloadsStore.subscribe(({ downloadsProgress }) => {
  setLazyValue('DownloadsStore__progress', downloadsProgress);
});

// Update download progress
if (typeof window !== 'undefined') {
  addEventListener('UPDATE_DOWNLOAD_PROGRESS', ((
    event: CustomEvent<{ url: string; progress: number }>
  ) => {
    const { url, progress } = event.detail;
    const compoundId = urlToEpisodeCompoundId(url);
    if (progress === -1) {
      deleteProgress(compoundId);
    } else {
      useDownloadsStore.getState().setProgress(compoundId, progress);
    }
  }) as EventListener);

  addEventListener('DOWNLOAD_FAILED', ((
    event: CustomEvent<{ error: any; url: string }>
  ) => {
    const { error, url } = event.detail;
    const compoundId = urlToEpisodeCompoundId(url);
    deleteProgress(compoundId);
    log('Download failed', compoundId, error);

    // If this download was recently requested, notify user & remove queued download
    const requestedTimestamp = getDownloadRequestedTimestamp(compoundId);
    if (requestedTimestamp && (Date.now() - requestedTimestamp) / 1000 / 60 < 1) {
      const { idToEpisode } = usePodcastsStore.getState();
      const episode = idToEpisode(compoundId);
      if (episode) {
        checkEpisodeAfterFailure(episode, 'download_episode').then(
          ({ isSaved, status }) => {
            showMessageWhenPlaybackFails(isSaved, status, 'download');
          }
        );
      } else {
        toast.error('Download failed');
      }
      removeFromDownloads(compoundId);
    }
  }) as EventListener);
}

function checkDownloadedFilesAtInterval(intervalMs: number) {
  // Only check downloads if nothing user-related has happened
  // in the past interval. We only want to do it once an account
  // state has been stable for a good while
  if (Date.now() - lastSupabaseEventTime > intervalMs) {
    try {
      checkDownloadedFiles(
        () => useDownloadsStore.getState(),
        () => useUserDataStore.getState()
      );
    } catch (error) {
      reportError(error, { context: 'checkDownloadedFiles failed' });
    }
  }

  // Check again in a minute
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      requestIdleCallback(() => checkDownloadedFilesAtInterval(intervalMs));
    }, intervalMs);
  }
}
