import { debounce, isEqual, uniq } from 'lodash-es';
import create from 'zustand';
import { fetchEpisode, fetchEpisodes, fetchPodcast } from '../utils/api';
import { reportError } from '../utils/error-handling';
import {
  exposeOnWindow,
  isEpisodeIdentifier,
  nowInSeconds,
  onlyUnique,
} from '../utils/helpers';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import { currentEpisode } from './audio-signals';
import log from './debug';
import { useUserDataStore } from './user-data';

export type PodcastId = `${number}`;

export type Podcast = {
  id: PodcastId;
  name: string;
  slug: string;
  artistName: string;
  feedUrl: string;
  images: Array<{ url: string; size: number }>;
  episodeCount: number;
  description?: string;
  episodes: Episode[];
  optOutReason?: string;
};

type SerializedPodcast = Podcast & { episodes: SerializedEpisode[] } & {
  // Until id was changed from int -> text, it was stored as a number in LocalStorage
  id: number | PodcastId;
};

export type Ad = {
  startTime: number;
  endTime: number;
};

// PodcastId|EpisodeGuidHash
export type EpisodeCompoundId = `${PodcastId}|${string}`;

// Episode from the API
export type EpisodeFromApi = {
  title: string;
  pubDate: string;
  guidHash: string;
  filename: string;
  filesize: number;
  // API will always provide full description
  description: string;
  ads: Ad[] | null;
  duration?: number;
  isSaved: boolean;
  // Derived from Last-Modified header
  queriedAt: number;
  // Provided ONLY for episodes that we don't allow ad-skipping on
  url?: string;
};

// Episode stored in LocalStorage
export type SerializedEpisode = {
  title: string;
  pubDate: string;
  guidHash: string;
  filename: string;
  filesize: number;
  // LocalStorage will only store excerpt (to avoid running out of space)
  excerpt?: string;
  ads: Ad[] | null;
  duration?: number;
  isSaved: boolean;
  queriedAt: number;
  url?: string;
};

export class Episode {
  title!: string;
  pubDate!: Date;
  guidHash!: string;
  filename!: string;
  filesize!: number;
  // Full description from API. Excerpt from LocalStorage, used before full
  // description is fetched
  description!: string | undefined;
  excerpt!: string | undefined;
  ads!: Ad[] | null;
  duration: number | undefined;
  isSaved!: boolean;
  queriedAt!: number;
  podcast!: Podcast;
  url?: string;

  constructor(episode: SerializedEpisode | EpisodeFromApi, podcast: Podcast) {
    if (!podcast) {
      console.log('episode', episode);
      throw new Error('Missing podcast for Episode');
    }

    this.title = episode.title;
    this.pubDate = new Date(episode.pubDate);
    this.guidHash = episode.guidHash;
    this.filename = episode.filename;
    this.filesize = episode.filesize;
    this.description = 'description' in episode ? episode.description : undefined;
    this.excerpt = this.description
      ? getExcerpt(this.description)
      : 'excerpt' in episode
        ? episode.excerpt
        : undefined;
    this.ads = episode.ads;
    this.duration = episode.duration;
    this.isSaved = episode.isSaved;
    this.queriedAt = episode.queriedAt;
    this.url = episode.url;

    // TEMP: Ensure that episode duration is a number (it used to be a string in the database
    // and is probably still stored that way in LocalStorage)
    if (typeof this.duration === 'string') {
      this.duration = parseFloat(this.duration) || undefined;
    }

    // Add podcast property but don't save it to JSON
    Object.defineProperty(this, 'podcast', {
      get() {
        return podcast;
      },
      enumerable: false,
    });
  }
  get podcastId() {
    return this.podcast.id;
  }
  get id(): EpisodeCompoundId {
    return `${this.podcast.id}|${this.guidHash}`;
  }
  get audioUrl() {
    // Use custom URL if provided
    if (this.url) return this.url;

    const baseUrl = 'https://podcasts.adblockpodcast.com/file/adp-podcast-mp3s';
    // Omit parameter so behavior is similar if service worker isn't enabled,
    // since service worker removes this parameter
    // const savedParameter = this.isSaved ? '' : '?saved=false';
    return `${baseUrl}/${this.podcast.slug}-${this.podcastId}/${this.filename}`;
  }
}

let reusedTemplate: HTMLTemplateElement;
function getExcerpt(description: string) {
  reusedTemplate = reusedTemplate || document.createElement('template');
  reusedTemplate.innerHTML = description;
  const { content } = reusedTemplate;

  let excerpt = '';
  let excerptTextLength = 0;

  let child = content.firstChild;

  // Fastest way to loop through children
  while (child) {
    excerpt += child instanceof HTMLElement ? child.outerHTML : child.nodeValue || '';
    excerptTextLength += (child.textContent || '.').length;
    if (excerptTextLength > 150) break;

    // Move to next sibling (faster than removing this child)
    child = child.nextSibling;
  }

  return excerpt;
}

type PodcastsStore = {
  podcasts: Record<string, Podcast>;
  sessionPodcastIds: Set<PodcastId>;
  addSessionPodcastId: (podcastId: PodcastId) => void;
  updatePodcast: (data: Podcast) => void;
  addOrUpdateEpisodes: (
    podcastId: PodcastId,
    episodes: Episode[],
    cursorGuidHash: string
  ) => void;
  addOrUpdateEpisode: (podcastId: PodcastId, episode: Episode) => void;
  setEpisodes: (podcastId: PodcastId, episodes: Episode[]) => void;
  idToEpisode: (id: string) => Episode | undefined;
};

export const usePodcastsStore = create<PodcastsStore>()((set, get) => ({
  podcasts: (function () {
    const podcastsObject = getInitialValue(
      'PodcastsStore__podcasts',
      (value) =>
        Boolean(
          value &&
            typeof value === 'object' &&
            Object.values(value).every(
              (obj) => obj && typeof obj === 'object' && obj.id && obj.name
            )
        ),
      {}
    );
    // @ts-ignore
    Object.values(podcastsObject).forEach((podcast: SerializedPodcast) => {
      // @ts-ignore
      podcast.id = podcast.id.toString(); // Convert number -> string (podcast IDs are now text)
      // @ts-ignore
      podcast.episodes = podcast.episodes.map(
        (ep: SerializedEpisode) => new Episode(ep, podcast)
      );
    });
    return podcastsObject;
  })(),
  sessionPodcastIds: new Set(),
  addSessionPodcastId: (podcastId) => {
    const { sessionPodcastIds } = get();
    sessionPodcastIds.add(podcastId);
  },
  updatePodcast: (podcast) => {
    const { id } = podcast;
    const { podcasts } = get();
    const oldPodcast = podcasts[id] as Podcast | undefined;
    if (!podcast) {
      console.error('No podcast to update', id);
      return;
    }

    const newPodcast = Object.assign({}, podcast);

    if (oldPodcast) {
      // Preserve description
      if (!newPodcast.description) {
        newPodcast.description = oldPodcast.description;
      }

      // Preserve episodes
      if (oldPodcast.episodes.length > newPodcast.episodes.length) {
        newPodcast.episodes = oldPodcast.episodes;
      }
    }

    if (isEqual(oldPodcast, newPodcast)) {
      return;
    }

    if (oldPodcast) {
      console.log(
        'Updated podcast',
        oldPodcast.name,
        Object.entries(oldPodcast).reduce((diff, [key, value]) => {
          // @ts-ignore
          if (!isEqual(value, newPodcast[key])) {
            // @ts-ignore
            return { ...diff, [key]: [value, newPodcast[key]] };
          }
          return diff;
        }, {})
      );
    } else {
      console.log('Added podcast', newPodcast.name);
    }

    set({
      podcasts: {
        ...podcasts,
        [id]: newPodcast,
      },
    });
  },
  addOrUpdateEpisodes: (podcastId, episodes, cursorGuidHash) => {
    const { podcasts } = get();
    const podcast = podcasts[podcastId];
    if (!podcast) {
      console.error('No podcast to update', podcastId);
      return;
    }

    // Swap out old episodes with new ones
    let allEpisodes = podcast.episodes.slice();

    // Find overlapping episodes
    const firstEpisode = episodes[0];
    const firstIndex = allEpisodes.findIndex(
      (ep) => ep.guidHash === firstEpisode.guidHash
    );
    const lastEpisode = episodes[episodes.length - 1];
    const lastIndex = allEpisodes.findIndex(
      (ep) => ep.guidHash === lastEpisode.guidHash
    );

    // In the case of a perfect overlap between old episodes & new episodes,
    // compare them and if they're identical, no updates are required
    const isPerfectOverlap = Boolean(
      firstIndex !== -1 &&
        lastIndex !== -1 &&
        lastIndex - firstIndex + 1 === episodes.length
    );
    if (
      isPerfectOverlap &&
      JSON.stringify(episodes) ===
        JSON.stringify(allEpisodes.slice(firstIndex, lastIndex + 1))
    ) {
      // No updates required
      return;
    }

    // Keep track of any conflicting episodes that are newer than the fetched episodes. This can
    // happen if we've manually fetched a single episode (uncached) and then queried a group of
    // episodes (cached). We'll restore them later.
    const fetchEpisodesQueriedAt = firstEpisode.queriedAt;
    const moreRecentEpisodesToKeep = [];
    for (
      let i = firstIndex === -1 ? 0 : firstIndex;
      i < (lastIndex === -1 ? allEpisodes.length - 1 : lastIndex);
      i++
    ) {
      const episode = allEpisodes[i];
      if (episode.queriedAt > fetchEpisodesQueriedAt)
        moreRecentEpisodesToKeep.push(episode);
    }

    // Swap out existing episodes w/ just-fetched episodes. Ideally do this by swapping out a whole
    // array segment, both for performance and also to remove any episodes that are no longer in database
    // but were previously fetched by the client
    if (lastIndex !== -1 && firstIndex !== -1) {
      // Swap out overlapping episodes
      allEpisodes.splice(firstIndex, lastIndex - firstIndex + 1, ...episodes);
    } else if (lastIndex !== -1 && cursorGuidHash === '') {
      // These episodes are at the very beginning and the first episode is new
      allEpisodes.splice(0, lastIndex + 1, ...episodes);
    } else {
      // Manually swap out each episode w/ the one it matches, sigh
      episodes.forEach((episode) => {
        const index = allEpisodes.findIndex((ep) => ep.guidHash === episode.guidHash);
        if (index === -1) {
          allEpisodes.push(episode);
        } else if (JSON.stringify(allEpisodes[index]) !== JSON.stringify(episode)) {
          allEpisodes.splice(index, 1, episode);
        }
      });
    }

    // If any conflicting episodes were more recent than the ones we swapped them with, restore them
    moreRecentEpisodesToKeep.forEach((episode) => {
      const index = allEpisodes.findIndex((ep) => ep.guidHash === episode.guidHash);
      if (index !== -1 && episode.queriedAt > allEpisodes[index].queriedAt) {
        allEpisodes.splice(index, 1, episode);
      }
    });

    // Update currentEpisode signal if underlying data changed
    const currentEpisodeValue = currentEpisode.value;
    if (currentEpisodeValue && currentEpisodeValue.podcastId === podcastId) {
      const matchingNewEpisode = allEpisodes.find(
        (ep) => ep.guidHash === currentEpisodeValue.guidHash
      );
      if (
        matchingNewEpisode &&
        JSON.stringify(matchingNewEpisode) !== JSON.stringify(currentEpisodeValue)
      ) {
        currentEpisode.value = matchingNewEpisode;
      }
    }

    console.log('Updated episodes', podcast.name, allEpisodes.length);

    // Necessary when adding a batch of new episodes to a sparse group of episodes (only relevant old episodes
    // are stored in LocalStorage, not all old episodes)
    allEpisodes = allEpisodes.sort((a, b) => (a.pubDate > b.pubDate ? -1 : 1));

    set({
      podcasts: {
        ...podcasts,
        [podcastId]: Object.assign({}, podcast, { episodes: allEpisodes }),
      },
    });
  },
  addOrUpdateEpisode: (podcastId, episode) => {
    const { podcasts } = get();
    const podcast = podcasts[podcastId];
    if (!podcast) {
      console.error('No podcast to update', podcastId);
      return;
    }

    // Swap out old episode with new one
    let allEpisodes = podcast.episodes.slice();

    let wasUpdated = false;

    const index = allEpisodes.findIndex((ep) => ep.guidHash === episode.guidHash);
    if (index === -1) {
      allEpisodes.push(episode);
      wasUpdated = true;
    } else if (episode.queriedAt > (allEpisodes[index].queriedAt || 0)) {
      // Update queriedAt property before comparing (so only content is compared)
      allEpisodes[index].queriedAt = episode.queriedAt;
      if (JSON.stringify(allEpisodes[index]) !== JSON.stringify(episode)) {
        allEpisodes.splice(index, 1, episode);
        wasUpdated = true;
        // Update currentEpisode to reflect change in audio player, lock screen, etc.
        if (currentEpisode.value && currentEpisode.value.id === episode.id) {
          currentEpisode.value = episode;
        }
      }
    } else if (episode.description && !allEpisodes[index].description) {
      // Just update description since we don't store that in LocalStorage anymore
      allEpisodes[index].description = episode.description;
      wasUpdated = true;
      // Update currentEpisode to reflect change in audio player, lock screen, etc.
      if (currentEpisode.value && currentEpisode.value.id === episode.id) {
        currentEpisode.value.description = episode.description; // Hmm, not sure if this will cause a re-render...
      }
    }

    if (!wasUpdated) {
      return;
    }

    console.log('Updated single episode', episode.id);

    set({
      podcasts: {
        ...podcasts,
        [podcastId]: Object.assign({}, podcast, { episodes: allEpisodes }),
      },
    });
  },
  setEpisodes: (podcastId, episodes) => {
    const { podcasts } = get();
    const podcast = podcasts[podcastId];
    if (!podcast) {
      console.error('No podcast to update', podcastId);
      return;
    }

    // Replace all episodes in podcast
    set({
      podcasts: {
        ...podcasts,
        [podcastId]: Object.assign({}, podcast, {
          episodes: [...episodes],
        }),
      },
    });
  },
  idToEpisode: (id: string) => {
    if (!isEpisodeIdentifier(id)) {
      throw new Error(`Invalid episode compound id: ${id}`);
    }
    const [podcastId, guidHash] = id.split('|');
    const podcast = get().podcasts[podcastId];
    if (podcast) {
      return podcast.episodes.find((ep) => ep.guidHash === guidHash);
    }
  },
}));

// Save updates in LocalStorage
usePodcastsStore.subscribe(({ podcasts }) => {
  // Only persist podcasts that user has recently interacted with or will likely
  // interact with down the road
  const {
    followedPodcastIds,
    downloadedEpisodeIds,
    recentEpisodeIds,
    listenLaterEpisodeIds,
  } = useUserDataStore.getState();
  const mostRecentEpisodeIds = recentEpisodeIds.slice(0, 50);

  const podcastIdsToStore: PodcastId[] = uniq([
    ...followedPodcastIds,
    ...downloadedEpisodeIds.map((compoundId) => compoundId.split('|')[0] as PodcastId),
    ...listenLaterEpisodeIds.map((compoundId) => compoundId.split('|')[0] as PodcastId),
  ]);

  const podcastsToStore = Object.fromEntries(
    Object.entries(podcasts)
      .filter(([id]) => {
        return podcastIdsToStore.includes(id as PodcastId);
      })
      .map(([key, podcast]) => [
        key,
        {
          ...podcast,
          // Don't store full descriptions in LocalStorage and only store recent episodes and episodes
          // that user has downloaded or saved for later
          episodes: podcast.episodes
            .map(
              (ep) =>
                ({
                  ...ep,
                  pubDate: ep.pubDate.toISOString(),
                  description: undefined,
                }) as SerializedEpisode
            )
            .filter((ep, index) => {
              // Save latest episodes for each podcast
              if (index < 20) return true;
              // Save episodes that user has downloaded, saved for later, or recently listened to
              const id = `${podcast.id}|${ep.guidHash}`;
              if (downloadedEpisodeIds.includes(id)) return true;
              if (listenLaterEpisodeIds.includes(id)) return true;
              if (mostRecentEpisodeIds.includes(id)) return true;
              return false;
            }),
        },
      ])
  );
  setLazyValue('PodcastsStore__podcasts', podcastsToStore);
});

// Fetch missing podcasts & episodes
// These were probably added/listened-to on another device
const checkForMissingPodcastsAndEpisodes = debounce(() => {
  const { podcasts } = usePodcastsStore.getState();
  const { followedPodcastIds, downloadedEpisodeIds, recentEpisodeIds } =
    useUserDataStore.getState();

  const missingPodcastsAndEpisodes: {
    podcastId: PodcastId; // Podcast that might need to be fetched
    episodeCompoundIds: string[]; // Episodes that need to be fetched
    isPodcastMissing: boolean;
  }[] = [];

  const updateMissing = (podcastId: PodcastId, episodeCompoundIds: string[]) => {
    const matchingObject = missingPodcastsAndEpisodes.find(
      (obj) => obj.podcastId === podcastId
    );
    if (matchingObject) {
      matchingObject.episodeCompoundIds.push(...episodeCompoundIds);
    } else {
      missingPodcastsAndEpisodes.push({
        podcastId,
        isPodcastMissing: !podcasts[podcastId],
        episodeCompoundIds,
      });
    }
  };

  followedPodcastIds.forEach((podcastId) => {
    if (!podcasts[podcastId]) updateMissing(podcastId, []);
  });

  [...downloadedEpisodeIds, ...recentEpisodeIds.slice(0, 20)]
    .filter(onlyUnique)
    .forEach((compoundId) => {
      const parts = compoundId.split('|');
      const podcastId = parts[0] as PodcastId;
      const podcast = podcasts[podcastId];
      if (!podcast || !podcast.episodes.some((ep) => ep.guidHash === parts[1])) {
        updateMissing(podcastId, [compoundId]);
      }
    });

  missingPodcastsAndEpisodes.forEach(
    ({ podcastId, isPodcastMissing, episodeCompoundIds }) => {
      fetchMissingPodcastAndEpisodes(podcastId, isPodcastMissing, episodeCompoundIds);
    }
  );
}, 1000);

// Fetch podcasts that are added to favorites but not yet in this store
useUserDataStore.subscribe(checkForMissingPodcastsAndEpisodes);
checkForMissingPodcastsAndEpisodes();

const missingPodcastIdsBeingFetched = new Set<PodcastId>();
async function fetchMissingPodcastAndEpisodes(
  id: PodcastId,
  isPodcastMissing: boolean,
  episodeIds: string[]
) {
  if (missingPodcastIdsBeingFetched.has(id)) return;

  missingPodcastIdsBeingFetched.add(id);

  if (isPodcastMissing) {
    log('Fetching missing podcast', id);
    try {
      await fetchPodcast(id);
    } catch (error) {
      reportError(error, 'Failed to fetch missing podcast', id);

      // Without podcast we shouldn't fetch episodes, so return early and try again later
      missingPodcastIdsBeingFetched.delete(id);
      return;
    }
  }

  if (episodeIds.length) {
    // Fetch first page of episodes if episodes haven't been queried in over a day
    const { podcasts, idToEpisode } = usePodcastsStore.getState();
    const { episodes } = podcasts[id];
    if (!episodes.length || nowInSeconds() - episodes[0].queriedAt > 60 * 60 * 24) {
      try {
        log('Fetching first episodes for missing podcast', id);
        await fetchEpisodes(id);
      } catch (error) {
        reportError(error, 'Failed to fetch first episodes for missing podcast', id);
      }
    }
    const missingEpisodeIds = episodeIds.filter((id) => !idToEpisode(id));
    if (missingEpisodeIds.length) {
      try {
        log('Fetching still-missing episodes for missing podcast', missingEpisodeIds);
        await Promise.all(
          missingEpisodeIds.map((compoundId) =>
            fetchEpisode(id, compoundId.split('|')[1])
          )
        );
      } catch (error) {
        reportError(error, 'Failed to still-missing episodes for missing podcast', id);
      }
    }
  }

  missingPodcastIdsBeingFetched.delete(id);
}

// Make available to audio-signals.ts w/out circular reference
exposeOnWindow({ globalPodcastsStore: usePodcastsStore });
