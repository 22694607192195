import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import toast from 'react-hot-toast';
import waitForTimers from 'wait-for-timers';
import FeedbackIcon from '../../assets/icons/feedback.svg';
import {
  currentDuration,
  currentSeek,
  getCurrentEpisode,
} from '../../models/audio-signals';
import { getNativeLogs } from '../../models/audio-state';
import debugLog, { getLogs } from '../../models/debug';
import { getUser } from '../../models/user';
import { requestApi } from '../../utils/api';
import { classNames, platform, secondsToTime } from '../../utils/helpers';
import { Dialog } from '../dialog';

const log = (...args: any[]) => {
  debugLog({ logName: 'feedback', logColor: 'gray' }, ...args);
};

export function FeedbackDialog({ onClose }: { onClose: () => void }) {
  const inputClassName =
    'block w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-indigo-500 focus:ring-2 focus:outline-none text-sm';
  const formRef = useRef<HTMLFormElement>(null);

  // Set default field values
  useEffect(() => {
    log('Opened Feedback dialog');

    const fields: any = formRef.current!.elements;

    fields.include_logs.checked = true;
    fields.platform.value = platform.toLowerCase();

    const user = getUser();
    if (user) {
      fields.name.value = user.name || '';
      fields.email.value = user.email;
    }

    const episode = getCurrentEpisode();
    if (episode) {
      fields.episode.value = episode.title;
      fields.podcast.value = episode.podcast.name;
    }

    fields.title.focus();
  }, []);

  const [submittingStep, setSubmittingStep] = useState<
    '' | 'waiting-for-logs' | 'submitting'
  >('');

  const nativeLogsPromise = useNativeLogs();

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    const feedback = Object.fromEntries(new FormData(formRef.current!).entries());

    if (feedback.include_logs) {
      setSubmittingStep('waiting-for-logs');
      await nativeLogsPromise.current!.then((logs) => {
        feedback.device_logs = logs ? logs.join('\n') : 'Failed to get logs';
      });
      feedback.browser_logs = getLogs(100)
        .map((arr) => JSON.stringify(arr))
        .join('\n');
    }

    setSubmittingStep('submitting');
    try {
      await submitFeedback(feedback);
      toast.success('Thanks for the feedback!');
      onClose();
    } catch (error) {
      toast.error(`Failed to submit feedback: ${error}`);
    }
    setSubmittingStep('');
  };

  return (
    <Dialog onClose={onClose}>
      <form ref={formRef} onSubmit={onSubmit}>
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
              <FeedbackIcon
                class="h-6 w-6 text-yellow-600"
                stroke="none"
                fill="currentColor"
              />
            </div>
            <div class="mt-3 grow text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Leave Feedback
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Thanks for helping to make this app better!
                </p>
              </div>
              <div class="mt-4 flex w-full flex-col gap-4 text-left">
                <div class="flex flex-col gap-4 sm:flex-row">
                  <label class="grow">
                    <strong class="mb-1 block">
                      Your Name <span class={'font-light'}>*</span>
                    </strong>
                    <input name="name" type="text" class={inputClassName} required />
                  </label>
                  <label class="grow">
                    <strong class="mb-1 block">
                      Your Email <span class={'font-light'}>*</span>
                    </strong>
                    <input name="email" type="email" class={inputClassName} required />
                  </label>
                </div>
                <label>
                  <strong class="mb-1 block">
                    Title <span class={'font-light'}>*</span>
                  </strong>
                  <input name="title" type="text" class={inputClassName} required />
                </label>
                <label>
                  <strong class="mb-1 block">
                    Summary <span class={'font-light'}>*</span>
                  </strong>
                  <textarea
                    name="summary"
                    class={classNames(inputClassName, 'text-sm')}
                    rows={4}
                    required
                  />
                </label>
                <p class="text-sm italic">
                  Please include the podcast episode if relevant:
                </p>
                <div class="flex flex-col gap-4 sm:flex-row">
                  <label class="grow">
                    <strong class="mb-1 block">Episode</strong>
                    <input name="episode" type="text" class={inputClassName} />
                  </label>
                  <label class="grow">
                    <strong class="mb-1 block">Podcast</strong>
                    <input name="podcast" type="text" class={inputClassName} />
                  </label>
                </div>
                <label class="flex items-center">
                  <input type="checkbox" name="include_logs" />
                  <strong class="my-1 ml-2">
                    Include logs{' '}
                    <span class="font-normal">(recommended if reporting a bug)</span>
                  </strong>
                </label>
                <input type="hidden" name="platform" />
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="submit"
            class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            disabled={submittingStep !== ''}
          >
            {submittingStep === ''
              ? 'Leave Feedback'
              : submittingStep === 'waiting-for-logs'
                ? 'Fetching logs...'
                : 'Submitting...'}
          </button>
          <button
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  );
}

function useNativeLogs() {
  const nativeLogsPromiseRef = useRef<Promise<string[] | undefined>>();

  useEffect(() => {
    // Wait for dialog to open before getting native logs, since it can freeze up UI a bit
    const { resolve, promise: nativeLogsPromise } = withResolvers<
      string[] | undefined
    >();
    nativeLogsPromiseRef.current = nativeLogsPromise;

    return waitForTimers([500, 'ric'], () => {
      getNativeLogs().then(resolve, (error) => {
        log('Failed to get native logs', error);
        resolve(undefined);
      });
    });
  }, []);

  return nativeLogsPromiseRef;
}

async function submitFeedback(data: Record<string, any>) {
  // Include podcast data in "context"
  const currentEpisode = getCurrentEpisode();
  data.context = JSON.stringify({
    relevantEpisode: data.episode,
    relevantPodcast: data.podcast,
    currentEpisode: currentEpisode
      ? `${currentEpisode.id} ${currentEpisode.title}`
      : '(none)',
    currentSeek: secondsToTime(currentSeek.peek(), 2),
    currentDuration: secondsToTime(currentDuration.peek(), 2),
  });
  delete data.episode;
  delete data.podcast;
  delete data.include_logs;

  return requestApi(
    'https://didgjhjnevdixtjltmho.functions.supabase.co/record-feedback',
    data,
    { Authorization: `Bearer ${getUser()!.accessToken}` }
  );
}

export interface Deferred<T> {
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason: unknown) => void;
  promise: Promise<T>;
}

export function withResolvers<T = void>(): Deferred<T> {
  let resolve!: (value: T | PromiseLike<T>) => void;
  let reject!: (reason: unknown) => void;
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  return { resolve, reject, promise };
}
