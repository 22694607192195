import { uniq } from 'lodash-es';
import {
  currentDuration,
  currentEpisode,
  currentSeek,
  getRequestedSeek,
  getRequestedState,
  playbackRate,
  RequestedAudioState,
  requestedSeek,
  requestedState,
  state,
} from '../models/audio-signals';
import debugLog from '../models/debug';
import { DownloadsStore } from '../models/downloads';
import { Episode, usePodcastsStore } from '../models/podcasts';
import { UserDataStore } from '../models/user-data';
import { checkEpisodeAfterFailure, reportError } from './error-handling';
import {
  exposeOnWindow,
  isIOS,
  promptBrowserUpgrade,
  secondsToTime,
  urlToCacheKey,
  urlToEpisodeCompoundId,
} from './helpers';

const log = (...args: any[]) => {
  debugLog({ logName: 'audio', logColor: 'DodgerBlue' }, ...args);
};

class VanillaAudioPlayer {
  element: HTMLAudioElement;
  skipSoundElement: HTMLAudioElement;
  failToSkipSoundElement: HTMLAudioElement;
  broadcast: BroadcastChannel;
  messageHandlers: ((event: MessageEvent) => void)[];
  downloadAbortControllers: Record<string, AbortController>;
  hasRecentlyFixedAudio: boolean;

  constructor() {
    log('Using Vanilla Audio Player');
    this.element = new Audio();
    this.element.crossOrigin = 'anonymous';
    this.element.preload = 'auto'; // Download audio right away
    exposeOnWindow({ aud: this.element });

    this.hasRecentlyFixedAudio = false;

    // loadeddata fires when we have enough data to seek to requested spot in audio
    this.element.addEventListener('loadeddata', () => {
      if (!this.hasCorrectSeek()) {
        log(`loadeddata event -> seekTo(${secondsToTime(getRequestedSeek(), 1)})`);
        this.seekTo(getRequestedSeek());
      }
    });

    this.element.addEventListener('canplay', () => {
      if (getRequestedState() === 'playing') {
        log('canplay event -> play()');
        this.play();
      } else if (getRequestedState() === 'paused') {
        log('canplay event -> pause()');
        this.pause();
      } else {
        log('Unhandled canplay event', {
          state: state.value,
          requestedState: getRequestedState(),
        });
      }
    });

    this.element.addEventListener('error', () => {
      const { error } = this.element;
      const printableError = { code: error!.code, message: error!.message };
      if (state.value === 'loading' || state.value === 'error') {
        log('loading error', printableError);
        state.value = 'error';
      } else {
        log('Unhandled error event', state.value, getRequestedState(), printableError);
      }
    });

    this.element.addEventListener('seeked', () => {
      const priorSeekValue = currentSeek.value;
      this.updateSeek();
      const requestedSeekValue = getRequestedSeek();
      const threshold = 2;
      let seekFailureReason;
      if (Math.abs(currentSeek.value - requestedSeekValue) > threshold) {
        seekFailureReason = 'seek does not match requested';
        log('Seek failed', {
          requested: secondsToTime(requestedSeekValue, 2),
          actual: secondsToTime(currentSeek.value, 2),
        });
      } else if (
        currentSeek.value === 0 &&
        priorSeekValue > threshold &&
        Number.isNaN(requestedSeekValue)
      ) {
        seekFailureReason = 'seek set to zero when not requested';
        log('Seek to zero unexpected', {
          priorSeek: secondsToTime(priorSeekValue, 2),
          duration: secondsToTime(currentDuration.value, 2),
        });
      }

      if (seekFailureReason) {
        if (!this.hasRecentlyFixedAudio) {
          this.fixAudio(getRequestedState(), requestedSeekValue, seekFailureReason);
        } else {
          reportError('Unable to fix audio');
        }
      } else {
        log(
          'Successfully seeked',
          secondsToTime(currentSeek.value, 2),
          secondsToTime(requestedSeekValue, 2)
        );
        if (state.value === 'seeking') {
          if (getRequestedState() === 'playing') {
            this.play();
          } else if (getRequestedState() === 'paused') {
            this.pause();
          }
        }
      }
    });

    this.element.addEventListener('durationchange', () => {
      const { duration } = this.element;
      const newDuration = !duration || duration === Infinity ? NaN : duration;
      if (newDuration !== currentDuration.value) {
        log('durationchange event -> update duration to', newDuration);
        currentDuration.value = newDuration;
      }
      // Enforce playback rate if it gets reset to 1.0
      if (this.element.playbackRate !== playbackRate.value) {
        this.element.playbackRate = playbackRate.value;
      }
    });

    this.element.addEventListener('ended', () => {
      const { duration, currentTime } = this.element;
      log('ended event', {
        duration,
        currentTime,
        currentDuration: currentDuration.value,
      });
      if (Math.abs(duration - currentDuration.value) > 2) {
        // This happened when I pressed the seek back 10 seconds button a bunch of times
        // Eventually it fired an "ended" event where duration & currentTime were identical
        // even though it was in the middle of the podcast.
        // Not sure if ignoring the event will fix it--we may need to call fixAudio()
        log('Ignoring ended event b/c it does not match currentDuration');
      } else if (state.value === 'playing') {
        this.pause();
        this.updateSeek();
      }
    });

    if (isIOS) {
      addEventListener('APP_VISIBLE', ((event: CustomEvent<{ hiddenFor: number }>) => {
        const { hiddenFor } = event.detail;
        if (
          hiddenFor >= 5 &&
          state.value === 'paused' &&
          currentSeek.value &&
          !this.hasRecentlyFixedAudio
        ) {
          this.fixAudio('paused', currentSeek.value, 'tab hidden');
        }
      }) as EventListener);
    }

    this.skipSoundElement = new Audio();
    this.failToSkipSoundElement = new Audio();
    this.setupSkipSoundElements();

    if (!('BroadcastChannel' in window)) {
      promptBrowserUpgrade();
    }
    this.broadcast = new BroadcastChannel('audio-downloads');
    this.messageHandlers = [this.handleProgressEvents.bind(this)];
    this.broadcast.onmessage = (event) => {
      this.messageHandlers.forEach((fn) => fn(event));
    };

    this.downloadAbortControllers = {};
  }

  hasCorrectSeek() {
    const requestedSeekValue = getRequestedSeek();
    return (
      Number.isNaN(getRequestedSeek()) ||
      Math.abs(this.element.currentTime - requestedSeekValue) < 3
    );
  }

  loadNewEpisode(episode: Episode, seek: number, playSkipAdSound = false) {
    this.element.src = episode.audioUrl;
    this.element.load();
    this.element.currentTime = seek;

    // @todo -> handle error!!!

    if (navigator.mediaSession) {
      // Update metadata on lock screen / browser UI
      const podcast = usePodcastsStore.getState().podcasts[episode.podcastId];
      navigator.mediaSession.metadata = new MediaMetadata({
        title: episode.title,
        artist: podcast.artistName,
        album: podcast.name,
        artwork: podcast.images.map(({ url, size }) => ({
          src: url,
          sizes: `${size}x${size}`,
        })),
      });
    }

    if (playSkipAdSound) {
      this.playSkipAdSound();
    }
  }

  reset() {
    log('reset');
    this.element.src = '';
    state.value = 'unloaded';

    if (navigator.mediaSession) {
      // Reset metadata on lock screen / browser UI
      navigator.mediaSession.metadata = null;
    }
  }

  async fixAudio(nextState: RequestedAudioState, seek: number, reason: string) {
    log('Attempting to fix audio:', reason, nextState, secondsToTime(seek, 2));
    if (!['playing', 'paused'].includes(nextState)) {
      log(`Coercing unexpected nextState value: "${nextState}" -> paused`);
      nextState = 'paused';
    }
    state.value = 'loading';
    requestedState.value = nextState;
    requestedSeek.value = seek;
    this.hasRecentlyFixedAudio = true;

    log('Fix: load()');
    this.element.load();
    setTimeout(() => {
      this.hasRecentlyFixedAudio = false;
    }, 3000);
  }

  play() {
    log('play()', this.element.readyState);

    // Reset src if we're in an error state
    if (state.value === 'error') {
      log('reset .src to get out of error state');
      this.element.src = this.element.src;
      // Reset state or we'll hit this same condition next time play() is called
      state.value = 'loading';
      // play() will be called on canplay event. Don't call it here or audio
      // will be loaded twice!
      return;
    }

    this.element.play().then(
      () => {
        state.value = 'playing';
      },
      (error) => {
        log('Play failed', error);
      }
    );
  }

  pause() {
    this.element.pause();
    state.value = 'paused';
  }

  seekTo(seconds: number, playSkipAdSound = false) {
    if (!this.element.paused && document.visibilityState === 'visible') {
      // Pause before seek unless tab is in background, in which case we won't be able to play after seek
      this.element.pause();
    }
    if (playSkipAdSound) {
      this.playSkipAdSound();
    }
    this.element.currentTime = seconds;
  }

  playFailToSkipAdSound() {
    if (isIOS && document.visibilityState !== 'visible') {
      log('Not playing fail-to-skip-ad sound on iOS b/c tab is hidden');
      return;
    }

    log('Playing fail-to-skip-ad sound');
    this.failToSkipSoundElement.play();
  }

  updateSeek() {
    currentSeek.value = this.element.currentTime;
    return Promise.resolve();
  }

  updatePlaybackRate(rate: number) {
    this.element.playbackRate = rate;
  }

  //
  // Skipping ads
  //

  setupSkipSoundElements() {
    this.skipSoundElement.src = '/assets/skip-ad-sound.mp3';
    this.skipSoundElement.preload = 'auto';
    this.failToSkipSoundElement.src = '/assets/fail-to-skip-ad-sound.mp3';
    this.failToSkipSoundElement.preload = 'auto';
    addEventListener(
      'touchstart',
      () => {
        log('Load sounds');
        this.skipSoundElement.load();
        this.failToSkipSoundElement.load();
      },
      { once: true }
    );
  }

  playSkipAdSound() {
    if (isIOS && document.visibilityState !== 'visible') {
      log('Not playing skip-ad sound on iOS b/c tab is hidden');
      return;
    }

    log('Playing skip-ad sound');
    this.skipSoundElement.play();
  }

  //
  // Bump priority when download fails
  //

  bumpEpisodePriority() {}

  //
  // Downloading audio
  //

  async downloadAudioFile(episode: Episode, retryCount = 0) {
    const cacheKey = urlToCacheKey(episode.audioUrl);

    // Abort any prior requests
    const priorAbortController = this.downloadAbortControllers[cacheKey];
    if (priorAbortController) priorAbortController.abort();

    // Setup new request
    const abortController = new AbortController();
    const signal = abortController.signal;
    this.downloadAbortControllers[cacheKey] = abortController;
    try {
      const response = await fetch(episode.audioUrl, { signal });
      if (response.status >= 200 && response.status < 400) {
        log('Downloaded audio file', response.status);
      } else {
        throw new Error(`Unexpected ${response.status} response`);
      }
    } catch (error: any) {
      // @todo -> maybe report error?
      log(
        `Failed to download audio (${episode.id} - ${episode.title})`,
        (error as Error).toString()
      );
      // Fetch episode to check on status
      try {
        let { status, isSaved, retryInSeconds } = await checkEpisodeAfterFailure(
          episode,
          'download_episode'
        );
        if (typeof retryInSeconds === 'number') {
          // Retry download if appropriate
          retryInSeconds += retryCount ** 2;
          log(`Trying again after delay: ${retryInSeconds}s (${episode.id})`, {
            status,
            isSaved,
          });
          setTimeout(() => {
            // Try again unless we've requested it since this request was queued
            if (abortController === this.downloadAbortControllers[cacheKey]) {
              this.downloadAudioFile(episode, ++retryCount);
            }
          }, retryInSeconds * 1000);
        }
      } catch (episodeError: any) {}
    }
  }

  async deleteAudioFile(episode: Episode) {
    const cacheKey = urlToCacheKey(episode.audioUrl);

    // Cancel any in-progress download
    // Unfortunately doesn't seem to propagate to service worker
    const abortController = this.downloadAbortControllers[cacheKey];
    if (abortController) {
      abortController.abort();
      delete this.downloadAbortControllers[cacheKey];
    }

    // Remove item from cache
    if ('caches' in window) {
      const cache = await caches.open('audio-files');
      await cache.delete(cacheKey);
    } else {
      // In localhost on Safari, caches exists only in ServiceWorker context
      this.broadcast.postMessage({
        type: 'DELETE_AUDIO_FILE',
        cacheKey,
      });
    }
  }

  async checkDownloadedFiles(
    getDownloadsState: () => DownloadsStore,
    getUserDataState: () => UserDataStore
  ) {
    if (!navigator.serviceWorker) {
      return console.warn('Service worker not supported');
    }
    if (!navigator.serviceWorker.controller) {
      return console.warn('Service worker not installed');
    }

    // Check cache storage to ensure cached files match the user's desired cached files
    log('Checking cache...');

    const cachedIds = (await this.getCachedAudioURLs()).map((url) =>
      urlToEpisodeCompoundId(url)
    );
    // log({ cachedIds });

    // Downloads in state (should match cache)
    const { downloadsProgress, setProgress } = getDownloadsState();
    // Downloads that user has requested
    const { downloadedEpisodeIds, recentEpisodeIds } = getUserDataState();
    const { idToEpisode } = usePodcastsStore.getState();

    // Download any episodes that should be in cache but aren't
    // Never queue up more than 2 episodes at a time
    let queuedEpisodeCount = 0;
    downloadedEpisodeIds.forEach((id) => {
      if (cachedIds.includes(id)) {
        // Ensure downloadsProgress is set to 1
        if (!downloadsProgress[id] || downloadsProgress[id][0] < 1) {
          setProgress(id, 1);
        }
      } else if (
        !downloadsProgress[id] ||
        Date.now() - downloadsProgress[id][1] > 30 * 1000
      ) {
        // Episode has no progress -or- has stalled
        const episode = idToEpisode(id);
        if (episode) {
          // Force download
          if (queuedEpisodeCount < 2) {
            console.warn('Force download missing episode audio to cache', id);
            this.downloadAudioFile(episode);
            queuedEpisodeCount++;
          }
        } else {
          console.error('Unable to find episode to force download!', id);
        }
      }
    });

    // Remove any episodes in cache that shouldn't be there anymore
    const validIdsToCache = uniq(
      [
        ...downloadedEpisodeIds,
        ...recentEpisodeIds.slice(0, 2),
        currentEpisode.value && currentEpisode.value.id,
      ].filter(Boolean)
    );
    cachedIds
      .filter((id) => !validIdsToCache.includes(id))
      .forEach((id) => {
        const episode = idToEpisode(id);
        if (episode) {
          this.deleteAudioFile(episode);
          log('Removing episode audio', id);
        } else {
          console.error('Unable to remove episode audio -- episode not found', id);
        }
      });
  }

  async getCachedAudioURLs(): Promise<string[]> {
    if ('caches' in window) {
      const cache = await caches.open('audio-files');
      return (await cache.keys()).map((request) => request.url);
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => reject('timeout'), 1000);
        this.broadcast.postMessage({
          type: 'GET_CACHED_AUDIO_URLS',
        });
        const handler = (event: MessageEvent) => {
          if (event.data.type === 'CACHED_AUDIO_URLS') {
            resolve(event.data.urls);
          }
          this.messageHandlers = this.messageHandlers.filter((fn) => fn !== handler);
        };
        this.messageHandlers.push(handler);
      });
    }
  }

  handleProgressEvents(event: MessageEvent) {
    if (event.data && event.data.type === 'DOWNLOAD_PROGRESS') {
      const { url, progress } = event.data;
      window.dispatchEvent(
        new CustomEvent('UPDATE_DOWNLOAD_PROGRESS', {
          detail: {
            url,
            progress,
          },
        })
      );
    }
    if (event.data && event.data.type === 'LOG') {
      const dataToLog: any[] = JSON.parse(event.data.data);
      const index = dataToLog.shift();
      const logColor =
        index % 3 === 0 ? 'LightCoral' : index % 3 === 1 ? 'LightSalmon' : 'Salmon';
      debugLog({ logName: `sw ${index}`, logColor }, ...dataToLog);
    }
  }
}

export default VanillaAudioPlayer;
