import toast from 'react-hot-toast';
import waitForTimers from 'wait-for-timers';
import { Episode, EpisodeCompoundId, PodcastId } from '../models/podcasts';

export function isPlayable(episode: Episode) {
  const audioExtensions = ['mp3', 'm4a', 'wav'];
  return audioExtensions.includes(getExtension(episode));
}

export function getExtension(episode: Episode) {
  return episode.filename.split('.').pop() || 'unknown';
}

export function getPlayableError(episode: Episode) {
  const extension = getExtension(episode);
  if (extension === 'mp4') {
    return "Sorry, videos aren't supported yet";
  }
  return `Unsupported format: ${extension}`;
}

export function isPodcastIdentifier(val: unknown): val is PodcastId {
  return Boolean(typeof val === 'string' && /^[1-9][0-9]*$/.test(val));
}

export function isEpisodeIdentifier(val: unknown): val is EpisodeCompoundId {
  return typeof val === 'string' && /^\d+\|.+$/.test(val);
}

export function isDatetimeString(val: any) {
  return (
    typeof val === 'string' &&
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(val)
  );
}

export function compareSerialized(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const isIOS =
  typeof window !== 'undefined' &&
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

export const isIOSNativeApp =
  typeof window !== 'undefined' &&
  Boolean(
    window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.audio
  );

export const isAndroidNativeApp =
  typeof window !== 'undefined' &&
  Boolean(window.Android && window.Android.sendMessage);

export const platform = isIOSNativeApp ? 'iOS' : isAndroidNativeApp ? 'Android' : 'Web';

export const isLocalDevelopment =
  typeof window !== 'undefined' && location.protocol === 'http:';

let hasPromptedUpgrade = false;
export function promptBrowserUpgrade() {
  if (hasPromptedUpgrade) return;
  hasPromptedUpgrade = true;

  setTimeout(() => {
    toast.error(`Please update your web browser to use Adblock Podcast`, {
      duration: 100 * 1000,
    });
  }, 100);
}

export function deferredPromise() {
  let resolveLater;
  let rejectLater;
  const promise = new Promise((resolve, reject) => {
    resolveLater = resolve;
    rejectLater = reject;
  });
  return Object.assign(promise, {
    resolve: resolveLater as unknown as Function,
    reject: rejectLater as unknown as Function,
  });
}

export function urlToCacheKey(url: string) {
  return url.split('?')[0];
}

export function urlToEpisodeCompoundId(url: string) {
  const [folderName, filename] = url.split('?')[0].split('/').slice(-2);
  const guidHash = decodeURIComponent(filename).split('.')[0].split('__').pop();
  const podcastId = folderName.split('-').pop();
  return `${podcastId}|${guidHash}`;
}

export function secondsToTime(totalSeconds: number, decimalPlaces = 0) {
  if (Number.isNaN(totalSeconds)) return 'NaN';
  if (totalSeconds === Infinity) return 'Infinity';

  if (totalSeconds < 0) totalSeconds = 0;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = decimalPlaces
    ? (totalSeconds % 60).toFixed(decimalPlaces)
    : Math.floor(totalSeconds % 60);
  return (hours ? [hours, minutes, seconds] : [minutes, seconds])
    .map((num) => {
      const [integer, decimal] = num.toString().split('.');
      return [integer.padStart(2, '0'), decimal].filter(Boolean).join('.');
    })
    .join(':');
}

export function exposeOnWindow(obj: Record<string, unknown>) {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    Object.assign(window, obj);
  }
}

export function waitForEvent(target: EventTarget, eventName: string, timeoutMs = 100) {
  return new Promise<Event>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout;

    // Resolve as soon as Swift dispatches event
    target.addEventListener(
      eventName,
      ((event) => {
        clearTimeout(timeoutId);
        resolve(event);
      }) as EventListener,
      { once: true }
    );

    // Timeout if Swift doesn't respond soon
    timeoutId = setTimeout(() => {
      reject(
        new TimeoutError(`Timeout: Event ${eventName} not fired after ${timeoutMs}ms`)
      );
    }, timeoutMs);
  });
}

export const isOnServer =
  typeof process !== 'undefined' && process.env.IS_SSR === 'true';

class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export function getEpisodeDuration(episode?: Episode) {
  if (episode && episode.duration) return episode.duration;
  if (episode && episode.filesize) return episode.filesize / 16000; // Crude estimate
  return 5 * 60; // 5 minutes
}

export function onFocusOrTimeout(
  minTimeout: number,
  maxTimeout: number,
  callback: Function
) {
  let startTime = Date.now();
  let cleanupTimeout = () => {};
  const triggerCallback = () => {
    callback();
    startTime = Date.now(); // Reset focus state
    setupMaxTimeout(); // Reset max timeout state
  };

  // When maxTimeout is ellapsed, call callback if window is visible
  const setupMaxTimeout = () => {
    cleanupTimeout();
    cleanupTimeout = waitForTimers([maxTimeout], () => {
      if (document.visibilityState === 'visible') {
        // console.log('-- refetch on maxtimeout', maxTimeout);
        triggerCallback();
      } else {
        setupMaxTimeout();
      }
    });
  };
  setupMaxTimeout();

  // When window is focused, if minTimeout has ellapsed, call callback
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && Date.now() > startTime + minTimeout) {
      // console.log('-- refetch on mintimeout focused', minTimeout);
      triggerCallback();
    }
  };
  document.addEventListener('visibilitychange', onVisibilityChange);

  // Cleanup
  return () => {
    document.removeEventListener('visibilitychange', onVisibilityChange);
    cleanupTimeout();
  };
}

export function whenVisible(callback: () => void) {
  if (document.visibilityState === 'visible') {
    callback();
    return () => {}; // No cleanup required
  }
  // Call callback once on visibility change
  document.addEventListener('visibilitychange', callback, { once: true });
  return () => document.removeEventListener('visibilitychange', callback);
}

export function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export function nowInSeconds() {
  return Math.round(Date.now() / 1000);
}

/**
 * Merge multiple classnames into a single string
 */
export function classNames(...args: (string | false | undefined | null)[]) {
  return args.filter(Boolean).join(' ');
}
