const addSuffix = (title) => `${title} | Adblock Podcast`;

module.exports = [
  {
    url: '/',
    title: 'Adblock Podcast | Listen to any podcast without ads',
  },
  {
    url: '/now',
    title: addSuffix('Listen Now'),
  },
  {
    url: '/library',
    title: addSuffix('Your Library'),
  },
  {
    url: '/search',
    title: addSuffix('Search Podcasts'),
  },
  {
    url: '/downloads',
    title: addSuffix('Downloaded Podcasts'),
  },
  {
    url: '/sign-in',
    title: addSuffix('Sign In'),
  },
  {
    url: '/sign-up',
    title: addSuffix('Sign Up'),
  },
  {
    url: '/update-password',
    title: addSuffix('Update Password'),
  },
  {
    url: '/privacy-policy',
    title: addSuffix('Privacy Policy'),
  },
  {
    url: '/terms-of-use',
    title: addSuffix('Terms of Use'),
  },
  {
    url: '/attribution',
    title: addSuffix('Attribution'),
  },
  {
    url: '/blank-ssr-page',
  },
];
