import { Link } from 'preact-router/match';
import style from './style.scss';

const BottomNavBar = ({ isHidden = false }) => {
  const isAlreadyUpgraded = false;
  return (
    <nav class={style.navBar} style={isHidden ? 'transform: translateY(100%)' : ''}>
      <Link activeClassName={style.active} href="/now">
        Listen Now
      </Link>
      <Link activeClassName={style.active} href="/library">
        Library
      </Link>
      <Link activeClassName={style.active} href="/search">
        Search
      </Link>
      {!isAlreadyUpgraded && (
        <Link activeClassName={style.active} href="/pricing-in-app">
          Pricing
        </Link>
      )}
    </nav>
  );
};

export default BottomNavBar;
