import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import toast from 'react-hot-toast';
import UserMinusIcon from '../assets/icons/user-minus.svg';
import { useSessionStore } from '../models/session';
import { getUser, useUserActions } from '../models/user';
import { APIError, requestApi } from '../utils/api';
import { Dialog } from './dialog';

export function DeleteAccountDialog({ onClose }: { onClose: () => void }) {
  const { signOut } = useUserActions();
  const [isDeleting, setDeleting] = useState(false);

  const onConfirmDelete = () => {
    setDeleting(true);
    deleteAccount()
      .then(() => {
        toast.success('Your account has been deleted');
        useSessionStore.setState({ currentModal: null });
        signOut();
      })
      .catch(() => {
        toast.error(
          'Failed to delete account. Please try again or contact adblockpodcast@gmail.com for support.'
        );
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Dialog onClose={onClose}>
      <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <UserMinusIcon
              class="h-6 w-6 text-red-600"
              stroke="none"
              fill="currentColor"
            />
          </div>
          <div class="mt-3 grow text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              class="text-base font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Are you sure you want to delete your account?
            </h3>
            <div class="mt-2">
              <p class="mb-2 text-sm text-gray-500">
                Careful, this action cannot be undone.
              </p>
              <p class="mb-2 text-sm text-gray-500">
                If you delete your account, you will loose access to it on all devices,
                and your account metadata (episode history, favorited podcasts, etc)
                cannot be recovered.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          onClick={onConfirmDelete}
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          disabled={isDeleting}
        >
          {!isDeleting ? 'Permanently Delete My Account' : 'Deleting...'}
        </button>
        <button
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Dialog>
  );
}

async function deleteAccount() {
  const { id: userId, accessToken } = getUser() || {};

  interface ResponseData {
    data: { success: true };
  }

  const response = (
    await requestApi(
      'https://didgjhjnevdixtjltmho.functions.supabase.co/delete-account',
      { userId },
      { Authorization: `Bearer ${accessToken}` }
    )
  ).json as ResponseData;

  if (!response.data || !response.data.success) throw new APIError('Invalid response');
}
