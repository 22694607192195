import { exposeOnWindow, secondsToTime } from '../utils/helpers';

const shouldLog = true; //getCookie('debug') === 'true';
const logs: any[][] = [];
const style = `color: white; background: {{color}}; border-radius: 3px; padding: 2px 4px`;
const maxLogs = 500; // TEMP! REDUCE IN THE FUTURE

debugLog(
  { logName: 'startup', logColor: 'darkblue' },
  'Starting session',
  getCurrentCommitHash()
);

export default function debugLog(...args: any[]) {
  if (typeof window === 'undefined') return;

  let name = 'debug';
  let color = 'darkcyan';
  if (args[0] && typeof args[0] === 'object' && args[0].logName && args[0].logColor) {
    name = args[0].logName;
    color = args[0].logColor;
    args.shift();
  }

  // @ts-ignore
  if (shouldLog || window.debugLog) {
    console.log(`%c${name}`, style.replace('{{color}}', color), ...args); // eslint-disable-line no-console
  }
  if (name.startsWith('sw')) {
    args.unshift(name);
  }
  logs.push([
    Date.now(),
    ...args.map((val) => {
      if (!val || typeof val !== 'object') return val;
      try {
        return JSON.stringify(val);
      } catch (error) {
        return String(val);
      }
    }),
  ]);

  // Keep up to 100 audio logs in store
  if (logs.length > maxLogs) {
    logs.splice(0, logs.length - maxLogs);
  }
}

export function getLogs(amount = maxLogs): any[][] {
  const logsToReturn = amount < logs.length ? logs.slice(logs.length - amount) : logs;
  if (!logsToReturn.length) return [];
  return (JSON.parse(JSON.stringify(logsToReturn)) as any[][]).map((log) => {
    // Start at time zero and log in hh:mm:ss format
    log[0] = getTimeYmdHms(log[0]);
    return log;
  });
}

export function getCurrentCommitHash() {
  if (typeof window === 'undefined') return '';
  return (window.currentCommit || '').split('/').pop()!.slice(0, 7);
}

function getTimeYmdHms(timestamp: number) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const ms = date.getMilliseconds();

  const ymd = [year, month, day].map(zeroPad).join('-');
  const hms = [hour, minute, second].map(zeroPad).join(':');
  return `${ymd} ${hms}.${ms}`;
}

function zeroPad(x: number) {
  return x < 10 ? '0' + x : x;
}

function getCookie(name: string) {
  const match =
    typeof document !== 'undefined' &&
    document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return decodeURIComponent(match[2]);
}

function setCookie(name: string, value: string, expirationDays: number) {
  let expiration = '';
  if (expirationDays != null) {
    const date = new Date();
    date.setDate(date.getDate() + expirationDays);
    expiration = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${encodeURIComponent(value)}${expiration}; path=/`;
}

if (typeof document !== 'undefined') {
  let lastChangeTime = Date.now();
  let priorState = document.visibilityState;
  document.addEventListener('visibilitychange', () => {
    const seconds = Math.round((Date.now() - lastChangeTime) / 1000);
    const timeEllapsed = secondsToTime(seconds);
    const currentState = document.visibilityState;
    debugLog(`Visibility Change: ${currentState} (${priorState} for ${timeEllapsed})`);
    if (currentState === 'visible') {
      dispatchEvent(
        new CustomEvent('APP_VISIBLE', {
          detail: { hiddenFor: seconds },
        })
      );
    }
    lastChangeTime = Date.now();
    priorState = currentState;
  });

  // // Debug stuff not working when PWA is in background (e.g. iPhone is locked)
  // let lastTickTime = Date.now();
  // const logTick = function () {
  //   log('tick', Date.now() - lastTickTime);
  //   lastTickTime = Date.now();
  //   setTimeout(logTick, 5000);
  // };
  // setTimeout(logTick, 5000);
}

exposeOnWindow({ setCookie, getCookie, getLogs });
