import { storageFactory } from 'storage-factory';
import waitForTimers from 'wait-for-timers';
import debugLog from '../models/debug';

export const safeLocalStorage = storageFactory(() => localStorage);

const log = (...args: any[]) => {
  debugLog({ logName: 'localstorage', logColor: 'Gray' }, ...args);
};

let pendingValues: Record<string, any> = {};
let ricId: number;
onTabHidden(saveLazyValuesNow);

export function getInitialValue(
  key: string,
  validate?: (arg: unknown) => boolean,
  defaultValue?: any
) {
  try {
    const stringValue = safeLocalStorage.getItem(`ap__${key}`) as string;
    // De-serialize from string
    const value = JSON.parse(stringValue);
    if (!validate || validate(value)) return value;
  } catch (err) {}

  return defaultValue;
}

export function setLazyValue(key: string, value: any) {
  if (typeof window === 'undefined') return;

  pendingValues[key] = value;

  // Clear any pending save
  cancelIdleCallback(ricId);

  // Queue new save
  ricId = requestIdleCallback(saveLazyValuesNow);
}

function saveLazyValuesNow() {
  Object.entries(pendingValues).forEach(([key, value]) => {
    // Allow value to be a function (to delay expensive execution)
    if (typeof value === 'function') value = value();
    // Serialize to string
    const serializedValue = JSON.stringify(value);
    // Store safely
    safeLocalStorage.setItem(`ap__${key}`, serializedValue);
  });

  pendingValues = {};
}

export function onTabHidden(callback: Function) {
  if (typeof window === 'undefined') return;

  // Save everything if user changes tabs
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') callback();
  });

  // In Safari, we also need
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    addEventListener('beforeunload', () => callback());
  }
}

// TEMP - Cleanup lscache-* LocalStorage values (released April 22, only iOS alpha is in people's hands)
if (typeof window !== 'undefined') {
  waitForTimers([1000 * 10, 'ric'], () => {
    const keys: string[] = [];
    for (let i = 0; i < safeLocalStorage.length; i++) {
      const key = safeLocalStorage.key(i)!;
      if (key.startsWith('lscache-')) keys.push(key);
    }
    if (keys.length) {
      waitForTimers(['ric'], () => {
        log('Cleaning up lscache- keys', keys);
        keys.forEach((key) => safeLocalStorage.removeItem(key));
      });
    }
  });
}
