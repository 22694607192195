import { h } from 'preact';

export function Dialog({ onClose, children }: { onClose: () => void; children: any }) {
  const stopPropagation = (event: Event) => event.stopPropagation();
  return (
    <div
      class="relative"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ zIndex: '200' }}
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 overflow-y-auto" onClick={onClose}>
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div onClick={stopPropagation}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
