import { Episode } from '../models/podcasts';
import { getAdSkipCredits } from '../models/usage-data';
import { isPlayable } from './helpers';

export default function getAdsStatus(
  episode: Episode,
  showAdsLength = false,
  showCredits = true
) {
  if (episode.url) return 'Ad-skipping disabled';

  const adCount = (episode.ads && episode.ads.length) || 0;
  if (adCount) {
    const creditCount = getAdSkipCredits(episode.ads!);
    const adText = adCount > 1 ? 'ads' : 'ad';
    const creditText = creditCount > 1 ? 'credits' : 'credit';
    return showAdsLength
      ? `${adCount} ${adText} detected` +
          (showCredits ? ` • ${creditCount} ad-skip ${creditText}` : ' ✓')
      : `Ads detected` + (showCredits ? ` (${creditCount} ${creditText})` : ' ✓');
  }

  return episode.ads
    ? 'No ads detected ✓'
    : episode.isSaved
      ? 'Finding ads...'
      : isPlayable(episode)
        ? 'Preparing audio...'
        : ''; // Not playable in app (video or unsupported audio). We already tell user so no need to say it again here.
}

// // 7 -> ⑦
// function numberInCircle(int: number) {
//   return String.fromCodePoint(parseInt('0x' + (9311 + int).toString(16)));
// }
