import { h } from 'preact';
import { useRef } from 'preact/hooks';
import { Dialog } from './dialog';

export function WelcomeModal({ onClose }: { onClose: () => void }) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Dialog onClose={onClose}>
      <div class="bg-white px-6 pb-4 pt-8" ref={containerRef}>
        <div class="flex flex-col gap-3 overflow-hidden text-sm text-gray-600">
          <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
            Ah shucks, thanks for taking my app for a spin! 🎉
          </h3>
          <p>
            It's still a bit rough around the edges, but hopefully you'll find it skips
            most ads in the podcasts you care about. I've been working on this side
            project for nearly 4 years and still have a long way to go.
          </p>
          <p>
            You’ll start off with <strong>25 free ad-skip credits</strong> to play with.
            To get more, just sign up for a paid plan that supports podcast creators.
          </p>
          <p>
            My goal is to both support podcasters and make listening to podcasts more
            pleasant for everyone. Enjoy!
          </p>
          <p>- Micah</p>
          <button
            class="button ml-auto !rounded-md px-2 text-black"
            onClick={onClose}
            style={{
              background:
                'linear-gradient(35deg,#00afd6 0%,#00bedb 13%,#00cbd6 22%,#00dbcd 31%,#00e6b4 40%,#00f098 50%,#68f876 60%,#a3fd4e 73%,#dcff14 98%)',
            }}
          >
            Get started <small class="text-xs">❯</small>
          </button>
        </div>
      </div>
    </Dialog>
  );
}
