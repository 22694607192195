export {};

declare global {
  interface Window {
    Bugsnag?: {
      start: Function;
      notify: Function;
    };
  }
}

// Setup Bugsnag as soon as JS file loads

if (typeof window !== 'undefined') {
  attemptToSetupBugSnag(0);
}

function attemptToSetupBugSnag(attemptCount: number) {
  if (attemptCount > 100) {
    console.log('Failed to setup Bugsnag');
    return;
  }

  if (!window.Bugsnag) {
    setTimeout(() => attemptToSetupBugSnag(++attemptCount), 100);
    return;
  }

  window.Bugsnag.start({
    apiKey: 'a5a621a0ce58d80846255aa4a3f4b21f',
    appType: 'browser',
    collectUserIp: false,
    enabledReleaseStages: ['production'],
    maxEvents: 3,
    redactedKeys: ['accessToken', 'password'],
  });
}
