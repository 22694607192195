import { forwardRef, useRef, useState } from 'preact/compat';
import waitForTimers from 'wait-for-timers';
import { reportError } from '../../utils/error-handling';
import { isIOS } from '../../utils/helpers';
import style from './style.scss';
const emptyFn = () => {};

/**
 * A cross-browser implementation the "iOS bottom sheet" component, essentially a panel
 * that slides up from the bottom of the screen and whose contents can be scrolled, but
 * if you're already at the top and pull down, it collapses.
 * The browser does not support this behavior by default (you can either scroll or drag,
 * not both). It would be possible to implement this with `touch-action: none`, but then
 * we'd have to re-implement scrolling, which would feel janky to users and have poor
 * performance.
 * Instead, we preserve the browser's default scrolling behavior but listen for a variety
 * of events to collapse the panel when appropriate.
 */
const DragDownToCollapse = forwardRef(
  (
    {
      panelStyles,
      veilStyles,
      isExpanded,
      collapse,
      avoidPullDownToRefreshOnIOS,
      children,
    },
    scrollContainerRef
  ) => {
    const dragState = useRef();
    const panelRef = useRef();
    const cancelTimeoutRef = useRef(emptyFn);
    const cancelTransformRef = useRef(emptyFn);
    const previousWheelData = useRef();

    //
    // Non-iOS devices
    // Listen for touch start & pointer down, then listen to either touch move or pointer move
    // On devices that support touch, use touch events b/c they will be fired while scrolling
    // On devices that don't support touch, pointermove events will work
    // I know, it's a bit complex!
    //
    const [listenerType, setListenerType] = useState('initial'); // 'initial' or 'touch' or 'pointer' or 'wheel'

    const onPointerDown = (event) => {
      // Should never happen since we remove this listener with setListenerType()
      if (dragState.current) {
        reportError('Unexpected pointerdown', event);
        return;
      }

      if (
        event.pointerType !== 'touch' &&
        (['A', 'BUTTON', 'H2', 'P'].includes(event.target.nodeName) ||
          window.getSelection().type === 'Range')
      ) {
        // console.warn('Allow interaction instead of scroll');
        return;
      }

      dragState.current = {
        pointerId: event.pointerType === 'touch' ? undefined : event.pointerId,
        scrollDeficit: 0,
        lastPageY: event.pageY,
      };
      setListenerType(event.pointerType === 'touch' ? 'touch' : 'pointer');
      document.body.classList.add('disable-text-selection');
      // console.log('start', dragState.current);
    };

    const resetToInitialState = () => {
      cancelTransformRef.current();
      cancelTimeoutRef.current();
      dragState.current = undefined;
      setListenerType('initial');
      document.body.classList.remove('disable-text-selection');
      window.getSelection().removeAllRanges();
    };

    // Handle touchmove and pointermove events
    const onMove = (event) => {
      if (!dragState.current) {
        // Occasionally in Chrome, there's a pointermove right after a pointerup for
        // some reason. Ignore it, it doesn't break anything b/c we handle it here
        // reportError('Unexpected', event.type, event);
        return;
      }

      // Ensure we're only tracking a single pointer (e.g. finger)
      // - pointerdown & pointermove pointerIds should match
      // - For touch events, both values are undefined (we get pageY from first touch)
      if (event.pointerId !== dragState.current.pointerId) return;

      // There hasn't been a move event in 2 seconds. Stop listening for them.
      cancelTimeoutRef.current();
      cancelTimeoutRef.current = waitForTimers([2000], () => {
        console.warn('Timing out', event.type);
        onEndOrCancel(event);
      });

      const { scrollTop } = scrollContainerRef.current;
      if (scrollTop !== 0) return;

      const { pageY } = event.type === 'pointermove' ? event : event.touches[0];
      const diffY = pageY - dragState.current.lastPageY;
      dragState.current.lastPageY = pageY;
      dragState.current.scrollDeficit += diffY;
      const dampenFactor = 2.3; // Don't scroll as far as user has dragged

      // Collapse panel if they drag down to far
      if (
        dragState.current.scrollDeficit / dampenFactor >
        getDragDownCollapseThreshold()
      ) {
        collapse();
        resetToInitialState();
        return;
      }

      // Simulate a scroll
      cancelTransformRef.current = waitForTimers(['raf'], () => {
        panelRef.current.style.transition = 'transform ease 0.05s';
        panelRef.current.style.transform = `translateY(${
          dragState.current.scrollDeficit / dampenFactor
        }px)`;
      });
    };

    // On mouse devices, allow them to scroll up to simulate pulling down the panel
    const onWheel = (event) => {
      const { scrollTop } = scrollContainerRef.current;
      const { deltaY } = event;
      const deltaYAbsDiff = previousWheelData.current
        ? (deltaY - previousWheelData.current.deltaY) /
          (Math.abs(deltaY) + Math.abs(previousWheelData.current.deltaY))
        : 0;
      const timeSinceLastEvent =
        Date.now() -
        (previousWheelData.current ? previousWheelData.current.timestamp : 0);
      previousWheelData.current = {
        deltaY,
        timestamp: Date.now(),
      };
      const timeoutMs = 200;

      // console.log('wheel', listenerType, deltaY, deltaYAbsDiff, timeSinceLastEvent);

      // Start listening for wheel
      if (listenerType === 'initial') {
        if (dragState.current) {
          reportError('Unexpected', event.type, event);
        } else if (
          scrollTop === 0 &&
          deltaY < 0 &&
          (timeSinceLastEvent > 100 || Math.abs(deltaYAbsDiff) > 0.5)
        ) {
          // User started pulling touchpad/wheel down from a still position
          dragState.current = {
            scrollDeficit: -deltaY,
          };
          setListenerType('wheel');
          // There hasn't been a wheel event for a while. Stop listening for them.
          cancelTimeoutRef.current();
          cancelTimeoutRef.current = waitForTimers([timeoutMs], () => {
            onEndOrCancel(event);
          });
        }
        return;
      }

      // There hasn't been a wheel event for a while. Stop listening for them.
      cancelTimeoutRef.current();
      cancelTimeoutRef.current = waitForTimers([timeoutMs], () => {
        onEndOrCancel(event);
      });

      dragState.current.scrollDeficit -= deltaY;
      const dampenFactor = 3; // Don't scroll as far as user has wheeled

      // Collapse panel if they drag down to far
      if (
        dragState.current.scrollDeficit / dampenFactor >
        getDragDownCollapseThreshold()
      ) {
        collapse();
        resetToInitialState();
        return;
      }

      // Simulate a scroll
      cancelTransformRef.current = waitForTimers(['raf'], () => {
        panelRef.current.style.transition = 'transform ease 0.05s';
        panelRef.current.style.transform = `translateY(${
          dragState.current.scrollDeficit / dampenFactor
        }px)`;
      });
    };

    const onEndOrCancel = (event) => {
      avoidPullDownToRefreshOnIOS(scrollContainerRef.current);

      if (!dragState.current || event.pointerId !== dragState.current.pointerId) {
        return;
      }

      // Simulate scroll back to normal expanded position when user lifts pointer
      if (dragState.current.scrollDeficit !== 0) {
        requestAnimationFrame(() => {
          panelRef.current.style.transition = 'transform ease 0.3s';
          panelRef.current.style.transform = `translateY(0px)`;
        });
      }

      resetToInitialState();
    };

    // Collapse when clicking above or near top of panel
    const onClick = (event) => {
      if (
        event.target === scrollContainerRef.current ||
        ((event.target === panelRef.current ||
          event.target === panelRef.current.firstElementChild) &&
          event.offsetY <= 24)
      ) {
        collapse();
        resetToInitialState();
      }
    };

    //
    // iOS - Listen for scroll events to know when to collapse panel
    //
    const onScroll = () => {
      avoidPullDownToRefreshOnIOS(scrollContainerRef.current);

      const { scrollTop } = scrollContainerRef.current;
      if (scrollTop < -getDragDownCollapseThreshold() && isExpanded) {
        const element = scrollContainerRef.current.firstChild;
        element.style.transition = 'none';
        element.style.transform = `translateY(${-scrollTop}px)`;
        scrollContainerRef.current.style.overflowY = 'hidden';
        scrollContainerRef.current.scrollTop = 0;
        requestAnimationFrame(collapse);
        resetToInitialState();
      }
    };

    return (
      <>
        <div class={classes(['-veil'])} style={veilStyles}></div>
        <section
          class={classes([''])}
          style={panelStyles}
          ref={scrollContainerRef}
          onScroll={isExpanded && isIOS ? onScroll : undefined}
          onClick={isExpanded ? onClick : undefined}
          onWheel={
            isExpanded &&
            !isIOS &&
            (listenerType === 'initial' || listenerType === 'wheel')
              ? onWheel
              : undefined
          }
        >
          <div
            onPointerDown={
              isExpanded && listenerType === 'initial' ? onPointerDown : undefined
            }
            onTouchMove={
              isExpanded && listenerType === 'touch' && !isIOS ? onMove : undefined
            }
            onTouchEnd={
              isExpanded && listenerType === 'touch' ? onEndOrCancel : undefined
            }
            onTouchCancel={
              isExpanded && listenerType === 'touch' ? onEndOrCancel : undefined
            }
            onPointerMove={
              isExpanded && listenerType === 'pointer' && !isIOS ? onMove : undefined
            }
            onPointerUp={isExpanded ? onEndOrCancel : undefined}
            onPointerCancel={
              isExpanded && listenerType === 'pointer' ? onEndOrCancel : undefined
            }
            ref={panelRef}
            class={classes(['__panel'])}
          >
            <div class={classes('__content')}>
              <div
                class={classes('__pull-down-indicator')}
                style={{
                  backgroundColor: listenerType === 'initial' ? '' : '#bbb',
                }}
              ></div>
              {children}
            </div>
          </div>
        </section>
      </>
    );
  }
);

function classes(ems) {
  if (!Array.isArray(ems)) ems = [ems];
  return ems
    .filter((em) => typeof em === 'string')
    .map((em) => style['player-panel' + em])
    .join(' ');
}

// Get drag threshold (10% of screen height)
let lastThreshold;
if (typeof window !== 'undefined') {
  window.addEventListener('orientationchange', () => {
    lastThreshold = undefined;
  });
}
function getDragDownCollapseThreshold() {
  if (lastThreshold) return lastThreshold;

  lastThreshold = clamp(window.innerHeight / 8, 80, 120);
  return lastThreshold;
}

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

export default DragDownToCollapse;
