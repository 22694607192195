import { effect } from '@preact/signals';
import toast from 'react-hot-toast';
import {
  AudioState,
  currentDuration,
  currentEpisode,
  currentSeek,
  getRequestedState,
  getState,
  state,
} from '../models/audio-signals';
import log from '../models/debug';
import { DownloadsStore } from '../models/downloads';
import { Episode, Podcast, usePodcastsStore } from '../models/podcasts';
import {
  pollForPodcastEveryMinute,
  syncUsageDataNow,
  usageActions,
} from '../models/usage-data';
import { getUserDataId, UserDataStore, useUserDataStore } from '../models/user-data';
import { reportError, reportWarning } from './error-handling';
import getThumbUrl from './get-thumb';
import { getEpisodeDuration, waitForEvent } from './helpers';

const sendMessage = (message: { command: string; [key: string]: unknown }) => {
  try {
    // Note: if the message triggers a Java exception, it will throw a JS error
    window.Android!.sendMessage(JSON.stringify(message));
  } catch (error) {
    console.log('Android sendMessage failed', message);
    reportError(error);
  }
};

type UampMediaItem = {
  id: string;
  title: string;
  album: string;
  artist: string;
  genre: string;
  source: string;
  image: string;
  trackNumber: number;
  totalTrackCount: number;
  duration: number;
  site: string;
};

class AndroidAudioPlayer {
  constructor() {
    log('Android iOS Audio Player');
    // addEventListener('canplay', () => {
    // addEventListener('error', () => {
    // addEventListener('seeked', () => {
    // addEventListener('durationchange', () => {

    const androidState = { state: '', requestedState: '' };
    effect(() => {
      const state = getState();
      const requestedState = getRequestedState();
      if (
        state !== androidState.state ||
        requestedState !== androidState.requestedState
      ) {
        // Only update Android if need be. No need to send unnecessary messages
        androidState.state = state;
        androidState.requestedState = requestedState;
        sendMessage({
          command: 'updateState',
          state,
          requestedState,
        });
      }
    });

    // Let Android know what current episode's ad-skips are
    setTimeout(() => {
      effect(() => {
        if (currentEpisode.value) {
          this.updateAdSkips(currentEpisode.value);
        }
      });
    }, 10 * 1000);

    addEventListener('UPDATE_AUDIO_STATE', ((
      event: CustomEvent<{ state: AudioState }>
    ) => {
      const newState = event.detail.state;
      if (!newState) throw new Error(`Invalid state from Android: ${newState}`);
      androidState.state = newState;
      state.value = newState;
    }) as EventListener);

    addEventListener('UPDATE_SEEK', ((event: CustomEvent<{ seek: number }>) => {
      const { seek } = event.detail;
      if (typeof seek !== 'number')
        throw new Error(`Invalid seek from Android: ${seek}`);
      currentSeek.value = seek;
    }) as EventListener);

    addEventListener('UPDATE_DURATION', ((event: CustomEvent<{ duration: number }>) => {
      const { duration } = event.detail;
      if (typeof duration !== 'number')
        throw new Error(`Invalid duration from Android: ${duration}`);
      currentDuration.value = duration;
    }) as EventListener);

    // On Android, once screen has been off for 5+ minutes, setTimeout may be paused in the webview,
    // meaning that polling for the current podcast (once a minute) and polling for the current seek
    // (every second) ceases to work. In this case, the Android native app takes over and does both
    // of these things. Fortunately, it can still notify the webview to update state. I assume requests
    // will be sent once the app is once again in the foreground.
    addEventListener('MINUTE_PASSED', ((event: CustomEvent<{}>) => {
      pollForPodcastEveryMinute(true);
    }) as EventListener);

    addEventListener('AD_SKIPPED_NATIVELY', ((
      event: CustomEvent<{ duration: number }>
    ) => {
      const { duration } = event.detail;
      const { podcastId } = currentEpisode.peek() || {};
      if (podcastId) {
        usageActions.trackAdSkip(podcastId, duration);
      } else {
        reportWarning('No podcastId to track ad-skip', currentEpisode.peek());
      }
    }) as EventListener);

    this.setupUserIdSync();

    sendMessage({ command: 'readyForEvents' });

    setTimeout(() => this.precacheImagesToAvoidCrash());
  }

  loadNewEpisode(episode: Episode, seek: number, playSkipAdSound = false) {
    // Set new duration
    currentDuration.value = getEpisodeDuration(episode);

    // Create playlist for UAMP with a single MediaItem
    const podcast = usePodcastsStore.getState().podcasts[episode.podcastId];
    const mediaItem: UampMediaItem = {
      id: episode.id,
      title: episode.title,
      album: episode.podcast.name,
      artist: episode.podcast.artistName,
      genre: 'Podcast',
      source: episode.audioUrl,
      image: this.getPodcastImageUrl(podcast),
      trackNumber: 1,
      totalTrackCount: 1,
      duration: Math.round(getEpisodeDuration(episode)), // Needs to be an integer (Kotlin Long type)
      site: `${location.origin}/podcast/${podcast.id}`,
    };

    // Load new episode & update metadata
    sendMessage({
      command: 'loadNewEpisode',
      id: mediaItem.id,
      jsonPlaylist: JSON.stringify({
        music: [mediaItem],
      }),
      seek,
    });
    this.updateAdSkips(episode);
  }

  reset() {
    // @todo -> cancel loading and allow retry in the case of errors
    // sendMessage({ command: 'reset' });
    // this.element.src = '';
    // state.value = 'unloaded';
  }

  play() {
    sendMessage({ command: 'play' });
    // this.element.play().then(
    //   () => {
    //     state.value = 'playing';
    //   },
    //   (error) => {
    //     log('Play failed', error);
    //   }
    // );
  }

  pause() {
    sendMessage({ command: 'pause' });
    // this.element.pause();
    // state.value = 'paused';
  }

  seekTo(seconds: number, playSkipAdSound = false) {
    sendMessage({ command: 'seekTo', seconds, playSkipAdSound });
  }

  playFailToSkipAdSound() {
    sendMessage({ command: 'playFailToSkipAdSound' });
  }

  updatePlaybackRate(rate: number) {
    sendMessage({ command: 'updatePlaybackRate', rate });
  }

  async updateSeek() {
    // Ask Android for seek and receive response via event
    sendMessage({ command: 'updateSeek' });

    try {
      await waitForEvent(window, 'UPDATE_SEEK', 100);
    } catch (err: any) {
      reportError(err.message);
    }
  }

  //
  // Downloading audio
  //

  downloadAudioFile(episode: Episode) {
    sendMessage({
      command: 'downloadAudioFile',
      id: episode.id,
      url: episode.audioUrl,
    });
  }

  deleteAudioFile(episode: Episode) {
    sendMessage({
      command: 'deleteAudioFile',
      id: episode.id,
    });
  }

  async checkDownloadedFiles(
    getDownloadsState: () => DownloadsStore,
    getUserDataState: () => UserDataStore
  ) {
    console.log('Checking Android audio downloads...');
    sendMessage({
      command: 'getDownloadedFilesInfo',
    });

    const { detail } = (await waitForEvent(
      window,
      'DOWNLOAD_FILES_INFO',
      2500
    )) as CustomEvent<[{ id: string; percent: number }]>;
    const downloadsOnDevice = Object.fromEntries(
      detail.map(({ id, percent }) => [id, percent / 100])
    );

    // Downloads in state (should match cache)
    const { downloadsProgress, setProgress } = getDownloadsState();

    // Downloads that user has requested
    const { downloadedEpisodeIds: requestedDownloadIds } = getUserDataState();
    const { idToEpisode } = usePodcastsStore.getState();

    // For debugging
    console.log({ downloadsOnDevice, downloadsProgress, requestedDownloadIds });

    // Download any episodes that should be in cache but aren't
    // Never queue up more than 2 episodes at a time
    let queuedEpisodeCount = 0;
    requestedDownloadIds.forEach((id) => {
      if (downloadsOnDevice[id] === 1) {
        // Ensure downloadsProgress is set to 1
        if (!downloadsProgress[id] || downloadsProgress[id][0] < 1) {
          setProgress(id, 1);
        }
      } else if (
        !downloadsProgress[id] ||
        Date.now() - downloadsProgress[id][1] > 30 * 1000
      ) {
        log('URL has no progress or is stalled', id, downloadsOnDevice[id]);
        // Episode has no progress -or- has stalled
        if (queuedEpisodeCount < 2) {
          const episode = idToEpisode(id);
          if (episode) {
            console.warn('Force download missing episode audio', id);
            this.downloadAudioFile(episode);
            queuedEpisodeCount++;
          } else {
            reportError(
              "Unable to download missing episode audio: can't find episode",
              id
            );
          }
        }
      }
    });

    // Remove any persisted episodes that shouldn't be saved anymore
    Object.entries(downloadsOnDevice)
      .filter(([id]) => !requestedDownloadIds.includes(id))
      .forEach(([id]) => {
        log('Deleting file that should no longer be cached', id);
        sendMessage({ command: 'deleteAudioFile', id });
      });
  }

  //
  // Getting logs / debugging
  //

  async getNativeLogs() {
    sendMessage({ command: 'getAndroidLogs' });

    const allLogs: string[] = [];

    // Due to message length contraints, Android will send logs in chunks of 100
    const handleChunk = ((event: CustomEvent<string[]>) => {
      console.log('Received logs', event.detail.length, allLogs.length);
      allLogs.push(...event.detail);
    }) as EventListener;
    addEventListener('RECENT_LOGS_CHUNK', handleChunk);

    await waitForEvent(window, 'RECENT_LOGS_COMPLETE', 20 * 1000);

    // No longer listen for chunks
    removeEventListener('RECENT_LOGS_CHUNK', handleChunk);

    return allLogs;
  }

  //
  // RevenueCat user subscription
  //

  setupUserIdSync() {
    // Wait for userDataStore to be setup
    setTimeout(() => {
      let lastSyncedUserId: string | null = null;
      const syncUserIdIfChanged = () => {
        const userId = getUserDataId();
        if (userId && userId !== lastSyncedUserId) {
          sendMessage({ command: 'updateUserId', userId });
          lastSyncedUserId = userId;
        }
      };
      syncUserIdIfChanged();
      useUserDataStore.subscribe(syncUserIdIfChanged);
    });
  }

  async makePurchase(offeringId: string, packageId: string) {
    sendMessage({
      command: 'makePurchase',
      offeringId,
      packageId,
    });
    // Wait for user to complete transaction for up to 15 minutes
    await waitForEvent(window, 'PURCHASE_STATUS', 15 * 60 * 1000).then((event) => {
      const result = (
        event as CustomEvent<{ transaction: unknown } | { error: unknown }>
      ).detail;
      log('Purchase result', result);
      if ('error' in result) {
        if (result.error !== 'user_cancelled') {
          toast.error(`Payment failed: ${result.error}`);
        }
      } else {
        toast.success('Payment successful! Updating your account...');
      }
      syncUsageDataNow();
    });
  }

  //
  // Pre-cache all album art to avoid crash when offline
  // This is a weird workaround for UAMP, sigh. Essentially we have to pre-cache
  // album art before we play an episode or it'll crash. If the user goes offline
  // and this pre-caching fails, then the app will crash. So as a sanity check,
  // when they add a podcast for the first time and when they first open the app, we
  // pre-cache all the album art to make crashes less likely down the road
  //

  precacheImagesToAvoidCrash() {
    const { podcasts } = usePodcastsStore.getState();
    const imageUrls = Object.values(podcasts).map((p) => this.getPodcastImageUrl(p));
    sendMessage({
      command: 'cacheAlbumArtImages',
      imageUrls,
    });
  }

  getPodcastImageUrl(podcast: Podcast) {
    return getThumbUrl(podcast, 600);
  }

  // Let Android know what current episode's ad-skips are so it can skip ads if webview's timeouts are paused
  updateAdSkips(episode: Episode) {
    sendMessage({
      command: 'updateAdSkips',
      ads: JSON.stringify({ episodeId: episode.id, ads: episode.ads }),
    });
  }
}

export default AndroidAudioPlayer;
