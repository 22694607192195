import { uniq } from 'lodash-es';
import create from 'zustand';
import { isEpisodeIdentifier } from '../utils/helpers';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import { Episode, EpisodeCompoundId } from './podcasts';

/**
 * Temporary session or device-specific state. Most of it gets reset when app is closed & re-opened.
 * Some of it is stored in LocalStorage. But none of it is synced to API.
 */
type SessionStore = {
  listenNowTab: 'latest_episodes' | 'listen_later';
  setListenNowTab: (tab: 'latest_episodes' | 'listen_later') => void;
  isPageBehindVeil: boolean; // When top nav popup is open (or transitioning open/closed)
  previousRoute: string; // Empty string when no prior route
  isInApp: boolean;
  dismissedRecentEpisodeIds: EpisodeCompoundId[];
  dismissRecentEpisode: (episode: Episode) => void;
  lastActivationTimestamp: number | null;
  currentModal: 'feedback' | 'sign-in' | 'delete-account' | 'welcome' | null;
  recentAdSkips: `${EpisodeCompoundId}:${number}`[]; // EpisodeId & ad start time pairs
  trackRecentAdSkip: (episode: Episode, startTime: number) => void;
};

export const useSessionStore = create<SessionStore>()((set, get) => ({
  listenNowTab: 'latest_episodes',
  setListenNowTab: (tab) => set({ listenNowTab: tab }),
  isPageBehindVeil: false,
  previousRoute: '',
  isInApp: true,
  dismissedRecentEpisodeIds: getInitialValue(
    'SessionStore__dismissedRecentEpisodeIds',
    (val) => Array.isArray(val) && val.every((id) => isEpisodeIdentifier(id)),
    []
  ),
  dismissRecentEpisode: (episode) => {
    const dismissedRecentEpisodeIds = [episode.id, ...get().dismissedRecentEpisodeIds];
    set({ dismissedRecentEpisodeIds });

    // Store up to 100 in LocalStorage
    setLazyValue(
      'SessionStore__dismissedRecentEpisodeIds',
      dismissedRecentEpisodeIds.slice(0, 100)
    );
  },
  lastActivationTimestamp: getInitialValue(
    'sw_activation_timestamp',
    (val: any) => Number.isInteger(val),
    isServiceWorkerActive() ? Date.now() : null
  ),
  currentModal: null,
  recentAdSkips: getInitialValue(
    'SessionStore__recentAdSkips',
    (val) =>
      Array.isArray(val) &&
      val.every((val) => {
        const [id, startTime] = (typeof val === 'string' ? val : '').split(':');
        return isEpisodeIdentifier(id) && !Number.isNaN(parseFloat(startTime));
      }),
    []
  ),
  trackRecentAdSkip: (episode: Episode, startTime: number) => {
    // Keep track of 5 most recent ad-skips
    const idAndStartTime = `${episode.id}:${startTime}` as const;
    const recentAdSkips = uniq([idAndStartTime, ...get().recentAdSkips]).slice(0, 5);
    set({ recentAdSkips });
    setLazyValue('SessionStore__recentAdSkips', recentAdSkips);
  },
}));

// Update timestamp when new service worker is activated
if (typeof navigator !== 'undefined' && navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Activated new service worker');
    const timestamp = Date.now();
    useSessionStore.setState({ lastActivationTimestamp: timestamp });
    setLazyValue('sw_activation_timestamp', timestamp);
  });
}

export const useCurrentModal = () => useSessionStore((s) => s.currentModal);
export const showModal = (modalName: SessionStore['currentModal']) =>
  useSessionStore.setState({ currentModal: modalName });
export const hideModal = () => useSessionStore.setState({ currentModal: null });

function isServiceWorkerActive() {
  return Boolean(
    typeof navigator !== 'undefined' &&
      navigator.serviceWorker &&
      navigator.serviceWorker.controller
  );
}
