import { Podcast } from '../models/podcasts';

export default function getThumbUrl(podcast: Podcast, renderSize: number): string {
  const retinaMultiplier = Math.min(2, devicePixelRatio || 1);
  const idealSize = renderSize * retinaMultiplier;
  const image =
    podcast.images.find((image) => image.size >= idealSize) ||
    podcast.images[podcast.images.length - 1];

  return image && typeof image.url === 'string' ? image.url : '';
}
