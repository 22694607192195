import log from '../models/debug';
import { Episode } from '../models/podcasts';

// Used for SSR
class MockAudioPlayer {
  constructor() {
    log('Using SSR-only Audio Player ');
  }

  loadNewEpisode(episode: Episode, seek: number, playSkipAdSound: boolean) {}

  reset() {}

  play() {}

  pause() {}

  seekTo(seconds: number, playSkipAdSound: boolean) {}

  updateSeek() {
    return Promise.resolve();
  }
  playFailToSkipAdSound() {}

  updatePlaybackRate() {}

  //
  // Downloading audio
  //

  downloadAudioFile(episode: Episode) {}
  deleteAudioFile(episode: Episode) {}
  checkDownloadedFiles() {}
}

export default MockAudioPlayer;
