import {
  ComputedPropertyFunction,
  default as Collection,
  Entries,
  EntryValue,
  ValidationFunction,
} from 'timestamp-collection';
import { PodcastId } from '../models/podcasts';

// This really sucks, but I'm creating two identical versions of this file, one for Node and one for Deno
// Only difference is whether import path ends in `.ts`

export default function createUserDataCollections(
  importData: (name: string, collection: Collection<unknown>) => void
) {
  // Create Collections and expose getValue() function for transforming them
  // into an array with data in the format we prefer
  const followedPodcastIds = createEntryConfig(
    'followedPodcastIds',
    validatePodcastIdEntry,
    getPodcastIds,
    null
  );

  const podcastsLastViewedEpisodeDate = createEntryConfig(
    'podcastsLastViewedEpisodeDate',
    validatePodcastIdEntry,
    getTimestampsObject,
    null
  );

  const episodesProgress = createEntryConfig(
    'episodesProgress',
    validateEpisodeProgress,
    getProgressArray
  );

  const listenLaterEpisodeIds = createEntryConfig(
    'listenLaterEpisodeIds',
    validateEpisodeIdEntry,
    getSortedKeys,
    null
  );

  const recentEpisodeIds = createEntryConfig(
    'recentEpisodeIds',
    validateEpisodeIdEntry,
    getSortedKeys,
    null
  );

  const starredEpisodeIds = createEntryConfig(
    'starredEpisodeIds',
    validateEpisodeIdEntry,
    getUnsortedKeys,
    null
  );

  const downloadedEpisodeIds = createEntryConfig(
    'downloadedEpisodeIds',
    validateEpisodeIdEntry,
    getUnsortedKeys,
    null
  );

  return {
    followedPodcastIds,
    podcastsLastViewedEpisodeDate,
    episodesProgress,
    listenLaterEpisodeIds,
    recentEpisodeIds,
    starredEpisodeIds,
    downloadedEpisodeIds,
  };

  function createEntryConfig<DataType, ReturnType>(
    name: string,
    validateEntry: ValidationFunction,
    computedProperty: ComputedPropertyFunction<DataType, ReturnType>,
    defaultValue?: DataType
  ) {
    const collection = new Collection<DataType>({
      validateEntry,
      defaultValue,
    });
    importData(name, collection);
    return {
      collection,
      getValue,
    };
    function getValue(): ReturnType {
      return collection.get(computedProperty);
    }
  }
}

//
// Validation
//

function validatePodcastIdEntry(key: string, data: unknown) {
  // Keys are podcast IDs, no data is stored
  return isPodcastIdentifier(key) && data === null;
}

function validateEpisodeIdEntry(key: string, data: unknown) {
  // Keys are episode compound IDs, no data is stored
  return isEpisodeIdentifier(key) && data === null;
}

function validateEpisodeProgress(key: string, progress: unknown) {
  return (
    isEpisodeIdentifier(key) &&
    Array.isArray(progress) &&
    progress.length === 2 &&
    progress.every((num: number) => typeof num === 'number' && num >= 0)
  );
}

//
// Computed properties of Entries object
//

function getPodcastIds(entries: Entries<unknown>) {
  return Object.keys(entries).map((id) => id as PodcastId);
}

function getUnsortedKeys(entries: Entries<unknown>) {
  return Object.keys(entries);
}

function getSortedKeys(entries: Entries<unknown>) {
  return Object.entries(entries)
    .sort((a: [string, EntryValue<unknown>], b: [string, EntryValue<unknown>]) =>
      a[1][0] > b[1][0] ? -1 : 1
    )
    .map((arr) => arr[0]);
}

function getTimestampsObject(entries: Entries<unknown>) {
  const obj = {} as Record<string, number>;
  for (const key in entries) {
    obj[key] = entries[key][0];
  }
  return obj;
}

function getProgressArray(entries: Entries<[number, number]>) {
  const obj = {} as Record<string, [number, number]>;
  for (const key in entries) {
    obj[key] = entries[key][1];
  }
  return obj;
}

//
// HELPERS
// Duplicated code, but this way we avoid extra imports for Supabase Deno function
//

function isPodcastIdentifier(val: unknown): val is PodcastId {
  return typeof val === 'string' && /^[1-9][0-9]*$/.test(val);
}

function isEpisodeIdentifier(val: unknown) {
  return typeof val === 'string' && /^\d+\|.+$/.test(val);
}
