import { computed, effect } from '@preact/signals';
import { throttle } from 'lodash-es';
import toast from 'react-hot-toast';
import {
  clearTimeout as clearWorkerTimeout,
  setTimeout as setWorkerTimeout,
} from 'worker-timers';
import debugLog from '../models/debug';
import { Episode } from '../models/podcasts';
import {
  addToRecentEpisodes,
  bumpLastViewedEpisodeDate,
  setEpisodeProgress,
  UserDataStore,
  useUserDataStore,
} from '../models/user-data';
import AndroidAudioPlayer from '../utils/android-audio-player';
import {
  checkEpisodeAfterFailure,
  reportError,
  reportWarning,
  showMessageWhenPlaybackFails,
} from '../utils/error-handling';
import {
  exposeOnWindow,
  isAndroidNativeApp,
  isEpisodeIdentifier,
  isIOSNativeApp,
  isLocalDevelopment,
  secondsToTime,
  whenVisible,
} from '../utils/helpers';
import IOSAudioPlayer from '../utils/ios-audio-player';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import MockAudioPlayer from '../utils/mock-audio-player';
import VanillaAudioPlayer from '../utils/vanilla-audio-player';
import {
  currentDuration,
  currentEpisode,
  currentSeek,
  isFixingAudio,
  playbackRate,
  requestedSeek,
  requestedState,
  state,
} from './audio-signals';
import { DownloadsStore } from './downloads';
import { useSessionStore } from './session';
import { getAdSkipsSum, usageActions } from './usage-data';

const log = (...args: any[]) => {
  debugLog({ logName: 'audio-state', logColor: 'darkblue' }, ...args);
};

//
// Setup appropriate player for environment and preload initial audio
//

const player = isIOSNativeApp
  ? new IOSAudioPlayer()
  : isAndroidNativeApp
    ? new AndroidAudioPlayer()
    : typeof window !== 'undefined'
      ? new VanillaAudioPlayer()
      : new MockAudioPlayer();

// Persist episode progess as it's played (define early so it can be flushed when loading new episode)
const persistProgress = throttle(setEpisodeProgress, 500, {
  leading: true,
  trailing: true,
});

effect(() => {
  // Persist progress whenever episode, seek or duration change and episode is playing or seeking
  if (
    currentEpisode.value &&
    ['playing', 'seeking'].includes(state.value) &&
    currentSeek.value &&
    (currentDuration.value || currentEpisode.value.duration)
  ) {
    persistProgress(
      currentEpisode.value,
      currentSeek.value,
      currentDuration.value || (currentEpisode.value.duration as number)
    );
  }
});

//
// Expose actions that call player methods and update state
//

export function loadNewEpisode(episode: Episode, seek = 0, skipAdAtStart = true) {
  persistProgress.flush(); // Persist last episode's progress
  persistProgress.cancel(); // Persist this episode's progress as soon as it plays

  log(
    'Load new episode',
    episode.id,
    episode.title,
    (episode.ads || []).map(
      (ad) => `${secondsToTime(ad.startTime)} - ${secondsToTime(ad.endTime)}`
    )
  );

  // Skip initial ad
  let playSkipAdSound = false;
  if (
    skipAdAtStart &&
    seek < 1 &&
    episode.ads &&
    episode.ads[0] &&
    episode.ads[0].startTime < 1
  ) {
    seek = episode.ads[0].endTime;
    log('Skip ad at start of new episode', seek);
    playSkipAdSound = true;
    usageActions.trackAdSkip(episode.podcastId, seek);
  }

  // Update state
  state.value = 'loading';
  requestedState.value = 'paused';
  currentSeek.value = seek;
  currentEpisode.value = episode;
  currentDuration.value = NaN;

  player.loadNewEpisode(episode, seek, playSkipAdSound);
}

export function reset() {
  requestedState.value = 'unloaded';
  player.reset();
}

export function play() {
  requestedState.value = 'playing';
  player.play();

  // Add to recent episodes IDs
  const episode = currentEpisode.peek() as Episode;
  const compoundId = `${episode.podcastId}|${episode.guidHash}`;
  const { recentEpisodeIds } = useUserDataStore.getState();
  if (recentEpisodeIds[0] !== compoundId) {
    addToRecentEpisodes(compoundId);
  }

  // Update podcastsLastViewedEpisodeDate for this podcast
  bumpLastViewedEpisodeDate(episode);
}

export function pause() {
  requestedState.value = 'paused';
  player.pause();
}

export function seekTo(seconds: number, reason = '') {
  const requestedSeconds = seconds;

  // Should never happen, but avoid breaking things if it does
  if (Number.isNaN(seconds)) return reportError('NaN seek');
  if (seconds === Infinity) return reportError('Infinity seek');

  // Avoid negative seek
  if (seconds < 0) seconds = 0;

  // Use if-statement instead of clamp to handle NaN currentDuration
  if (seconds > currentDuration.value) seconds = currentDuration.value;

  if (requestedSeconds !== seconds)
    log(`seekTo() coerced invalid requested seconds: ${requestedSeconds}`);

  log('seekTo()', secondsToTime(seconds, 2), reason);

  requestedSeek.value = seconds;

  const stateBeforeSeek = state.value;
  state.value = 'seeking';

  // Update requestedState to indicate value after seeking
  // Probably not necessary for the audio to resume the correct state,
  // but ensures the data model doesn't get out of sync
  if (!requestedState.value) {
    requestedState.value = stateBeforeSeek === 'playing' ? 'playing' : 'paused';
  }

  player.seekTo(seconds, reason === 'skip ad');
}

function updatePlaybackRate(rate: number) {
  player.updatePlaybackRate(rate);
}

effect(() => {
  updatePlaybackRate(playbackRate.value);
});

//
// When episode fails to play, make sure it's been uploaded. If not,
// tell the user so they can try again in a little bit
//

effect(() => {
  const isErrorState = state.value === 'error'; // Only trigger when entering error state
  const episode = currentEpisode.peek();
  if (isErrorState && episode) {
    checkEpisodeAfterFailure(episode, 'play_episode').then(({ isSaved, status }) =>
      showMessageWhenPlaybackFails(isSaved, status, 'play')
    );
  }
});

//
// Preload initial episode
//

if (typeof window !== 'undefined') {
  // Load right away (not in requestIdleCallback) b/c it takes way too long on Chrome Android
  // and needs to run before Play() button is pressed
  setTimeout(() => {
    const episode = currentEpisode.value;
    const seekFromLastSession =
      'seekFromLastSession' in window ? window.seekFromLastSession : null;
    log(
      'Initial episode:',
      episode ? episode.id : '(none)',
      'Seek from last session:',
      seekFromLastSession ? JSON.stringify(seekFromLastSession) : '(none)'
    );
    if (episode) {
      const progress =
        useUserDataStore.getState().episodesProgress[getCompoundId(episode)];
      let seek = (progress && progress[0]) || 0;
      if (seekFromLastSession) {
        const {
          seek: nativeSeek,
          timestamp: nativeTimestamp,
          url,
        } = window.seekFromLastSession;
        if (url === episode.audioUrl && Math.abs(seek - nativeSeek) > 2) {
          // Natively stored seek for this episode differs significantly from seek in LocalStorage
          // Overwrite locally stored value if newer
          setEpisodeProgress(episode, nativeSeek, progress[1], nativeTimestamp);
          const updatedProgress =
            useUserDataStore.getState().episodesProgress[getCompoundId(episode)];
          if (updatedProgress[0] !== seek) {
            log(`Seek corrected by native app: ${seek} -> ${nativeSeek}`);
            seek = updatedProgress[0];
          } else {
            reportWarning(`Native app failed to correct seek`, {
              progress,
              updatedProgress,
              seekFromLastSession,
            });
          }
        } else if (url === episode.audioUrl) {
          log('Initial seek aligns with native app', seek, nativeSeek);
        } else {
          log('Initial episode differs from native app', episode.audioUrl, url);
        }
      }
      log(
        'load initial',
        Object.assign({}, episode, { description: '(omitted)' }),
        seek
      );
      loadNewEpisode(episode, seek, false);
    }
  }, 1);
}

//
// Setup async loop to update currentSeek and skip ads
//

const lastSeekWhilePlaying: { seek: number; timestamps: number[] } = {
  seek: -1,
  timestamps: [],
};
exposeOnWindow({ laskSeek: lastSeekWhilePlaying });

type PendingAdSkip = {
  ad: { startTime: number; endTime: number };
  whenMs: number;
};

let timeoutId: number;
const scheduleAudioLoop = (timeoutMs: number, pendingAdSkip?: PendingAdSkip) => {
  if (timeoutId) clearWorkerTimeout(timeoutId);
  timeoutId = setWorkerTimeout(() => audioPlayingLoop(pendingAdSkip), timeoutMs);
};

effect(() => {
  // Start loop when audio starts playing
  // Handle "paused" in case we go from paused -> seeking -> paused
  if (state.value === 'playing' && !isFixingAudio.value) {
    // Don't track dependencies within loop
    scheduleAudioLoop(0);
  }
  // Reset timestamps whenever state changes
  lastSeekWhilePlaying.timestamps = [];
});

// How deep into ad we can be before we no longer automatically skip it
export const nextAdThresholdSeconds = 2;

async function audioPlayingLoop(pendingAdSkip?: PendingAdSkip) {
  if (pendingAdSkip) {
    const timeToNextAdMs = pendingAdSkip.whenMs - Date.now();
    log('Start of loop w/ pending ad', timeToNextAdMs);
    skipAd(pendingAdSkip.ad);
    return;
  }

  // Update seek
  await player.updateSeek();
  const seek = currentSeek.value;

  // Not playing: no need to continue to update seek or skip ads
  if (state.value !== 'playing') return;

  // Fixing audio: no need to loop
  if (isFixingAudio.value) return;

  if (lastSeekWhilePlaying.seek === seek) {
    // Duplicate seek: record more timestamps
    lastSeekWhilePlaying.timestamps.push(Date.now());
    const { timestamps } = lastSeekWhilePlaying;
    if (timestamps.length > 1) {
      const interval = timestamps[timestamps.length - 1] - timestamps[0];
      log('Duplicate seek while playing:', seek, interval);
      if (interval > 2000) {
        if (player instanceof VanillaAudioPlayer && !player.hasRecentlyFixedAudio) {
          player.fixAudio('playing', seek, 'seek not changing');
        }
      }
    }
  } else {
    // Update to new seek value
    lastSeekWhilePlaying.seek = seek;
    lastSeekWhilePlaying.timestamps = [Date.now()];
  }

  // Align render time w/ seek seconds so UI is as accurate as possible
  // With worker timeout, we add a tiny bit to ensure it happens after the second tick
  // and we divide by the playback rate
  const timeToNextSecondMs =
    (Math.round(1000 - ((seek * 1000) % 1000)) + 5) / playbackRate.value;

  // We're already nearly at the second mark. No need to check again real soon
  const timeToWaitUntilNextAudioLoop =
    timeToNextSecondMs < 50 ? timeToNextSecondMs + 1000 : timeToNextSecondMs;

  // Find future ad (or ad that started recently)
  const { ads } = currentEpisode.value || {};

  const nextAd =
    ads && ads.find(({ startTime }) => startTime >= seek - nextAdThresholdSeconds);
  if (nextAd) {
    // If ad needs to be skipped within the next second or so, skip it!
    const timeToNextAdMs = Math.round(1000 * (nextAd.startTime - seek));
    const readyToSkipAd =
      timeToNextAdMs < Math.max(1000, timeToWaitUntilNextAudioLoop + 200);

    // Log if time to next ad is less than 10 seconds (for debugging ad skip failures)
    if (timeToNextAdMs < 10 * 1000) {
      log({
        toAd: timeToNextAdMs,
        ready: readyToSkipAd,
      });
    }

    if (readyToSkipAd) {
      // In the rare case that we've already passed the ad, skip it now
      if (timeToNextAdMs <= 0) return skipAd(nextAd);

      // We schedule audio loop with a queued ad instead of using setTimeout() b/c the
      // audio loop uses a worker timer which is more accurate
      scheduleAudioLoop(Math.max(0, timeToNextAdMs - 10), {
        ad: nextAd,
        whenMs: Date.now() + timeToNextAdMs,
      });
      return;
    }
  }

  // Keep looping
  scheduleAudioLoop(timeToWaitUntilNextAudioLoop);
}

let hasRecentlyPlayedFailToSkipAdSound = false;
let cancelNoAdSkipsToast = () => {};

async function skipAd({ startTime, endTime }: { startTime: number; endTime: number }) {
  // If user has no ad skips left, play failed to skip ad sound and don't skip ad
  const adSkipCreditsLeft = getAdSkipsSum();
  if (!adSkipCreditsLeft) {
    if (!hasRecentlyPlayedFailToSkipAdSound) {
      // If called repeatedly, ignore (can happen if audio is loading, etc)
      log('Not skipping ad b/c user is out of ad-skips', adSkipCreditsLeft);
      player.playFailToSkipAdSound();
      hasRecentlyPlayedFailToSkipAdSound = true;
      setTimeout(() => {
        hasRecentlyPlayedFailToSkipAdSound = false;
      }, 5000);

      // Show toast to user once tab is visible again (only show one toast if multiple are queued)
      cancelNoAdSkipsToast();
      cancelNoAdSkipsToast = whenVisible(() => toast.error('No ad-skips left'));
    }

    // Always continue audio loop
    scheduleAudioLoop(1000);
    return;
  }

  // Seek (and continue audio loop as soon as state is set back to playing)
  log('Skipping ad', { adSkipCreditsLeft });
  seekTo(endTime, 'skip ad');

  // TODO -> have native app report back what the seek was at the time of skipping
  // this is the only reliable way to know if I'm actually scheduling for the right time

  // Track ad skip
  const episode = currentEpisode.peek();
  if (episode) {
    const recentAdSkipId = `${episode.id}:${startTime}` as const;
    const { recentAdSkips, trackRecentAdSkip } = useSessionStore.getState();
    trackRecentAdSkip(episode, startTime);

    if (!recentAdSkips.includes(recentAdSkipId)) {
      usageActions.trackAdSkip(episode.podcastId, endTime - startTime);
      log('Ad recently skipped. Not consuming credit...');
    }
  } else {
    reportWarning('No podcastId to track ad-skip', currentEpisode.peek());
  }
}

export async function getNativeLogs() {
  return 'getNativeLogs' in player ? player.getNativeLogs() : undefined;
}

//
// Handle browser events for currently playing audio
//

if (
  typeof window !== 'undefined' &&
  !isIOSNativeApp &&
  !isAndroidNativeApp &&
  navigator.mediaSession
) {
  navigator.mediaSession.setActionHandler('play', () => {
    play();
  });
  navigator.mediaSession.setActionHandler('pause', () => {
    pause();
  });
  navigator.mediaSession.setActionHandler('seekbackward', ({ seekOffset }) => {
    seekOffset = seekOffset || 10;
    seekTo(currentSeek.value - seekOffset, `ms seekbackward ${seekOffset}`);
  });
  navigator.mediaSession.setActionHandler('seekforward', ({ seekOffset = 30 }) => {
    seekOffset = seekOffset || 30;
    seekTo(currentSeek.value + seekOffset, `ms seekforward ${seekOffset}`);
  });
  computed(() => {
    if (['playing', 'paused'].includes(state.value)) {
      navigator.mediaSession.playbackState = state.value as 'playing' | 'paused';
    }
  });
}

//
// Handle downloading audio and removing audio downloads
//

export async function downloadAudioFile(episode: Episode) {
  return player.downloadAudioFile(episode);
}

export async function deleteAudioFile(episode: Episode) {
  return player.deleteAudioFile(episode);
}

export async function checkDownloadedFiles(
  getDownloadsState: () => DownloadsStore,
  getUserDataState: () => UserDataStore
) {
  player.checkDownloadedFiles(getDownloadsState, getUserDataState);
}

//
// Gross this shouldn't go here. Refactor eventually...
//

export async function restorePurchase() {
  if (player instanceof IOSAudioPlayer) player.restorePurchase();
}

export async function makeNativePurchase(
  stripeLookupKey: 'alpha_user_monthly_3.99' | '100_forever_ad_skips_for_5.99'
) {
  // Translate Stripe lookup key into corresponding native app purchase managed
  // by RevenueCat
  let offeringId;
  let packageId;
  if (stripeLookupKey === 'alpha_user_monthly_3.99') {
    offeringId = isLocalDevelopment ? 'paid_plan_v0_test' : 'paid_plan_v0';
    packageId = '$rc_monthly';
  } else if (stripeLookupKey === '100_forever_ad_skips_for_5.99') {
    offeringId = isLocalDevelopment ? '100_ad_skips_v0_test' : '100_ad_skips_v0';
    packageId = '$rc_lifetime';
  } else {
    return reportError(`Invalid lookup key for purchase: ${stripeLookupKey}`);
  }

  if ('makePurchase' in player) {
    return player.makePurchase(offeringId, packageId);
  }
  throw new Error('makePurchase missing on player');
}

//
// Get / set current episode in LocalStorage
//

setTimeout(() => {
  // Get episode whose id was stored in LocalStorage last session
  // Wait until globalPodcastsStore is defined to do so (can't use import due to circular refercne)
  const id = getInitialValue('currentEpisodeId', isEpisodeIdentifier);
  currentEpisode.value = id
    ? // @ts-ignore
      window.globalPodcastsStore.getState().idToEpisode(id)
    : undefined;
}, 0);

// Update LocalStorage every time episode changes
effect(() => {
  const episode = currentEpisode.value;
  setLazyValue(
    'currentEpisodeId',
    episode ? `${episode.podcastId}|${episode.guidHash}` : undefined
  );
});

// // @ts-ignore
// window.downloadHelpers = {
//   downloadAudioFile,
//   deleteAudioFile,
//   checkDownloadedFiles,
// };

exposeOnWindow({
  getNativeLogs,
  updatePlaybackRate, // TEMP
});

//
// Helpers
//

function getCompoundId(episode: Episode) {
  return `${episode.podcastId}|${episode.guidHash}`;
}
